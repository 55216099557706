import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_ONE_POST,
  DELETE_ONE_POST,
  UPDATE_ONE_POST,
} from 'src/lib/mutations';
import { POSTS } from 'src/lib/queries';
import { useApolloOptions } from '../api';

export function usePosts(args: object) {
  const options = useApolloOptions();

  return useQuery(POSTS, {
    ...options,
    fetchPolicy: 'cache-and-network' as const,
    ...args,
  });
}

export function useCreateOnePost() {
  const options = useApolloOptions();

  return useMutation(CREATE_ONE_POST, {
    ...options,
    refetchQueries: [POSTS],
  });
}

export function useDeleteOnePost() {
  const options = useApolloOptions();

  return useMutation(DELETE_ONE_POST, {
    ...options,
    refetchQueries: [POSTS],
  });
}

export function useUpdateOnePost() {
  const options = useApolloOptions();

  return useMutation(UPDATE_ONE_POST, {
    ...options,
    refetchQueries: [POSTS],
  });
}
