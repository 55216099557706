import { Fragment, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { ArrowUp } from 'src/assets/icons';
import Avatar from 'src/components/ui/Avatar/Avatar';
import IconButton from 'src/components/ui/Button/IconButton';
import { useWorkspaceContext } from 'src/context/workspaceContext';
import PostModal from './PostModal';
import PostsArena from './PostsArena';

interface PostTabContainerProps {
  projectId: string;
  workspaceSlug: string;
  projectData: any;
  myData: any;
  heightHeadingContainerRef: number;
}

const PostTabContainer = () => {
  const {
    projectId,
    workspaceSlug,
    projectData,
    myData,
    heightHeadingContainerRef,
  } = useOutletContext<PostTabContainerProps>();
  const { workspaceWorkstreams } = useWorkspaceContext();

  const [state, setState] = useState({
    createPost: false,
  });

  return (
    <Fragment>
      <div className="h-[100%] w-[100%] relative">
        {projectId && workspaceSlug && (
          <PostsArena
            heightHeadingContainerRef={heightHeadingContainerRef}
            workspaceSlug={workspaceSlug}
            collaborators={projectData?.collaborators}
            workStreams={workspaceWorkstreams || []}
            handleCreatePost={() => setState({ ...state, createPost: true })}
          />
        )}

        <div className="px-[32px] pt-4 pb-8 absolute z-5 bottom-0 right-0 h-[92px] w-full transition-all bg-[var(--backgrounds-on-canvas)] border-t border-[var(--border-default)]">
          <div className="w-full absolute h-[70px] top-[-50px] left-0 scrollBg_bottom" />
          <div className="flex relative w-full">
            <div className="absolute left-0 inset-y-0 px-2 grid place-content-center">
              <Avatar
                name={myData?.detail.fullName}
                src={myData?.detail.avatar?.url}
                alt={`${myData?.detail.fullName} profile picture at overlap workspace`}
                size={24}
              />
            </div>
            <TextareaAutosize
              name="form-field-name"
              placeholder="What would you like to share?"
              className="input resize-none bg-none placeholder:text-base placeholder:!font-medium !rounded-full w-full !bg-[var(--backgrounds-default)] !pl-11 !p-2 placeholder:text-[var(--text-disabled)] shadow-none"
              onClick={() => setState({ ...state, createPost: true })}
              readOnly
            />
            <div className="absolute right-0 inset-y-0 px-1.5 grid place-content-center">
              <IconButton
                onClick={() => setState({ ...state, createPost: true })}
                type="primary"
                size="standard"
                rounded="full"
                icon={<ArrowUp />}
              />
            </div>
          </div>
        </div>
      </div>

      {workspaceSlug && (
        <PostModal
          show={state.createPost}
          handleClose={() => setState({ ...state, createPost: false })}
          collaborators={projectData?.collaborators}
        />
      )}
    </Fragment>
  );
};

export default PostTabContainer;
