import { Fragment, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useUser } from 'src/context/userContext';
import { useWorkspaceContext } from 'src/context/workspaceContext';
import CreateTaskModal from './CreateTaskModal';
import TasksArena from './TasksArena';

interface TaskTabContainerProps {
  projectId: string;
  workspaceSlug: string;
  projectData: any;
  heightHeadingContainerRef: number;
}

const TaskTabContainer = () => {
  const { projectId, workspaceSlug, projectData, heightHeadingContainerRef } =
    useOutletContext<TaskTabContainerProps>();
  const { user } = useUser();
  const { workspaceWorkstreams } = useWorkspaceContext();

  const [state, setState] = useState({
    createTaskModal: false,
  });

  return (
    <Fragment>
      <div className="h-[100%] w-[100%] relative">
        {projectId && workspaceSlug && (
          <TasksArena
            myData={user}
            heightHeadingContainerRef={heightHeadingContainerRef}
            handleCreateTask={() =>
              setState({ ...state, createTaskModal: true })
            }
            collaborators={projectData?.collaborators}
            workStreams={workspaceWorkstreams}
          />
        )}
      </div>

      {workspaceSlug && (
        <CreateTaskModal
          show={state.createTaskModal}
          handleClose={() => setState({ ...state, createTaskModal: false })}
          workspaceSlug={workspaceSlug}
          collaborators={projectData?.collaborators}
          workStreams={projectData?.workspace.topics}
        />
      )}
    </Fragment>
  );
};

export default TaskTabContainer;
