import { Popover, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useWorkspace } from 'src/api/workspace/useWorkspace';
import { fuzzySearchRegex, transformToTitleCase } from 'src/lib/helpers';
import { IUser, IUserWorkspace } from 'src/lib/types';
// import Avatar from "src/components/Avatar";
import { useDebounce } from '@uidotdev/usehooks';
import classNames from 'classnames';
import { useProject } from 'src/api/project/project.service';
import { Plus } from 'src/assets/icons';
import Avatar from '../ui/Avatar/Avatar';
import HorizontalDivider from '../ui/HorizontalDivider';
import StatusTag from '../ui/tags/StatusTags';

interface ProjectCollaboratorsSelectProps {
  onChange: Function;
  projectId?: string;
  userId?: string | null;
  value: string[];
  workspaceSlug: string;
  readOnly?: boolean;
}

const ProjectCollaboratorsSelect: React.FC<ProjectCollaboratorsSelectProps> = (
  props
) => {
  const {
    projectId,
    value,
    onChange,
    workspaceSlug,
    userId,
    readOnly = true,
  } = props;
  const [searchTerm, setSearchTerm] = React.useState('');
  const [selectedOptions, setSelectedOptions] = useState<IUser[]>([]);

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const { loading, data } = useWorkspace({
    variables: {
      where: { slug: workspaceSlug },
    },
  });

  const { data: projectData } = useProject({
    variables: {
      where: {
        id: { equals: projectId },
        workspace: {
          slug: { equals: workspaceSlug },
        },
      },
    },
    onError: (error: any) => {
      console.error('error', error);
    },
  });

  const workspaceCollaborators = useMemo(() => {
    if (!data?.workspace?.members) return [];

    const regex = fuzzySearchRegex(debouncedSearchTerm);
    return data.workspace.members
      .map((member: IUserWorkspace) => member.user)
      .filter((user: IUser) =>
        debouncedSearchTerm.length ? regex.test(user.detail.fullName) : true
      );
  }, [data?.workspace?.members, debouncedSearchTerm]);

  useEffect(() => {
    if (workspaceCollaborators.length) {
      setSelectedOptions(
        workspaceCollaborators.filter(
          (user: IUser) =>
            value?.includes(user.id) || (userId && user.id === userId)
        )
      );
    }
  }, [workspaceCollaborators, value, userId]);

  const handleCheckboxChange = (option: IUser) => {
    const newSelectedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];

    setSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions.map(({ id }) => id));
  };

  if (loading) return null;

  const maxItemCount = 3;
  const collaboratorOptionsLength = selectedOptions.filter((each: IUser) => {
    if (userId && !projectId) {
      return each.id !== userId;
    }
    return true;
  }).length;

  const renderButton = () => {
    if (readOnly && !collaboratorOptionsLength) {
      return (
        <StatusTag value="No collaborators yet" color="default" size="medium" />
      );
    }

    return collaboratorOptionsLength ? (
      <ul className="flex items-center -space-x-2 rounded-lg w-fit hover:bg-[var(--backgrounds-default)] px-[6px] py-1">
        {selectedOptions.slice(0, maxItemCount).map((option, index) => (
          <li
            key={option.id}
            className={`relative rounded-full ${
              index > 0 ? 'border border-[var(--backgrounds-default)]' : ''
            }`}
            style={{ zIndex: index }}
          >
            <Avatar
              name={option.detail.fullName}
              src={option.detail?.avatar?.url}
              alt={option.detail.fullName}
              size={20}
            />
          </li>
        ))}
        {/* {selectedOptions.length > maxItemCount && (
          <li>
            <div className="h-5 pl-2 font-medium text-grey-300 text-xs grid place-content-center">
              {selectedOptions.length - maxItemCount}+
            </div>
          </li>
        )} */}
        {/* {Boolean(projectId) || (
            <li className="pl-3">
              <AddPlus size={20} color={(theme.textColor.grey as any)['300']} weight={2} />
            </li>
          )} */}
        <li>
          <div className="text-body-micro medium text-[var(--text-on-bg)] bg-[var(--backgrounds-default)] ml-3 p-1 rounded-md h-5 w-5 flex flex-col justify-center items-center">
            {selectedOptions.length}
          </div>
        </li>
      </ul>
    ) : (
      <div className="button ghost small justify-start w-fit transition !pl-1.5">
        <Plus />
        <span className="text-[var(--text-on-bg)] text-body-micro font-medium">
          Add collaborators
        </span>
      </div>
    );
  };

  const noSearchResults =
    workspaceCollaborators.length === 0 && debouncedSearchTerm.length > 0;

  return (
    <Popover
      className={`relative ${collaboratorOptionsLength > 0 ? '' : '-ml-1'}`}
    >
      {({ open }) => (
        <Fragment>
          <Popover.Button className={`${open ? '' : ''} justify-start`}>
            {renderButton()}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="fixed top-32 md:top-auto w-[85%] md:translate-x-0 -translate-x-[8%] -translate-y-[8%] md:translate-y-0 md:absolute right-1 md:left-0 z-10 mt-2 md:w-[250px] space-y-4">
              <div className="max-h-[400px] overflow-y-auto drop-group p-1">
                <div className="sticky top-0 z-10 bg-[var(--backgrounds-dropdowns)]">
                  {/* show this component when readonly is false */}
                  {!readOnly && (
                    <div className="relative bg-[var(--backgrounds-dropdowns)]">
                      {/* Search Term Input */}
                      <input
                        autoFocus
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        id="collaborator-search"
                        type=""
                        className="input-clear !h-[34px] py-3 !pl-2 !pr-2 w-full text-[var(--text-default)] text-body-small regular placeholder:text-[var(--text-neutral)]"
                        placeholder="Add Collaborators"
                      />
                      {workspaceCollaborators.length > 1 && (
                        <HorizontalDivider />
                      )}
                    </div>
                  )}
                  {/* {noSearchResults || (
                    <div className="py-3 px-4 text-xs text-grey-300">
                      Collaborators
                    </div>
                  )} */}
                </div>
                <div className="">
                  {noSearchResults && (
                    <p className="text-center p-4 text-body-micro regular text-[var(--text-neutral)] whitespace-break-spaces">
                      No search found for “
                      <span className="font-semibold">
                        {debouncedSearchTerm}
                      </span>
                      ” in your workspace.
                    </p>
                  )}
                  <ul className="flex flex-col gap-1">
                    {!readOnly &&
                      workspaceCollaborators.map((user: IUser) => (
                        <li
                          key={user.id}
                          className={
                            Boolean(userId) && user.id === userId
                              ? 'hidden'
                              : ''
                          }
                        >
                          <label
                            className={classNames(
                              'flex items-center gap-2 px-2 rounded-lg h-11 hover:bg-[var(--backgrounds-hover-clicked)]',
                              { 'cursor-pointer': !readOnly }
                            )}
                          >
                            <Avatar
                              name={user.detail.fullName}
                              src={user.detail?.avatar?.url}
                              alt={user.detail.fullName}
                              size={28}
                            />
                            <div className="flex-1">
                              <div className="text-body-small medium text-[var(--text-default)]">
                                {user.detail.fullName}
                              </div>
                              <div className="text-body-micro regular text-[var(--text-neutral)]">
                                {transformToTitleCase(user.detail.position)}
                              </div>
                            </div>
                            <div className="">
                              <input
                                disabled={Boolean(userId) && user.id === userId}
                                type="checkbox"
                                className="input-clear border border-[var(--border-default)] !rounded bg-[var(--backgrounds-on-canvas)] checked:bg-[rgba(129, 120, 239, 1)]"
                                value={user as any}
                                checked={selectedOptions.includes(user)}
                                onChange={() => handleCheckboxChange(user)}
                              />
                            </div>
                          </label>
                        </li>
                      ))}
                    {/* when its readonly, only show collaborators on the project */}
                    {readOnly &&
                      projectData?.project?.collaborators.map((user: IUser) => (
                        <li key={user.id}>
                          <label
                            className={classNames(
                              'flex items-center gap-2 px-2 rounded-lg h-11 hover:bg-[var(--backgrounds-hover-clicked)]',
                              { 'cursor-pointer': !readOnly }
                            )}
                          >
                            <Avatar
                              name={user.detail.fullName}
                              src={user.detail?.avatar?.url}
                              alt={user.detail.fullName}
                              size={28}
                            />
                            <div className="flex-1">
                              <div className="text-body-small medium text-[var(--text-default)]">
                                {user.detail.fullName}
                              </div>
                              <div className="text-body-micro regular text-[var(--text-neutral)]">
                                {transformToTitleCase(user.detail.position)}
                              </div>
                            </div>

                            <div className="">
                              <input
                                disabled={readOnly}
                                type="checkbox"
                                className={`sr-only input-clear border border-[var(--border-default)] !rounded bg-[var(--backgrounds-on-canvas)] checked:bg-[rgba(129, 120, 239, 1)]`}
                                value={user as any}
                                checked={selectedOptions.includes(user)}
                                onChange={() => handleCheckboxChange(user)}
                              />
                            </div>
                          </label>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Fragment>
      )}
    </Popover>
  );
};

export default ProjectCollaboratorsSelect;
