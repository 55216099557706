import React from 'react';
import toast from 'react-hot-toast';
import { Close } from 'src/assets/icons';
import Button from './ui/Button/Button';

function ToastMessage({
  title,
  buttonText,
  buttonAction,
  icon,
  children,
  id,
}: {
  id: any;
  children?: React.ReactNode;
  buttonAction?: Function;
  icon?: React.ReactNode;
  title: string;
  buttonText?: string;
}) {
  const handleDismiss = () => toast.dismiss(id);

  return (
    <div className="grid place-items-center gap-4 grid-cols-[1fr_12px] w-full text-body-small medium !text-[var(--text-on-bg)]">
      {/* {icon && <div className="py-1">{icon}</div>} */}

      <div className="w-full flex items-center justify-between gap-x-4">
        {title && <h2 className="!text-[var(--Text-on-bg)]">{title}</h2>}
        {buttonAction && buttonText && (
          <Button
            onClick={() => buttonAction()}
            type="button"
            size="small"
            className="w-fit"
            value={buttonText}
            btnType={'neutral'}
          />
        )}
      </div>

      <button onClick={handleDismiss} className="w-fit">
        <Close />
      </button>
    </div>
  );
}

export default ToastMessage;
