import { AnimatePresence, motion } from 'framer-motion';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteOnePost } from 'src/api/post/post.service';
import ToastMessage from 'src/components/ToastMessage';
import Modal from 'src/components/ui/Modals/Modal';
import { IPost } from 'src/lib/types';

export default function DeletePostModal(props: {
  show: boolean;
  handleClose: Function;
  post?: IPost;
}) {
  const { show, handleClose, post } = props;

  const navigate = useNavigate();
  const { workspaceSlug, projectId, postId } = useParams<{
    workspaceSlug: string;
    projectId: string;
    postId: string;
  }>();

  const [deleteOnePost, { loading: deleteOnePostLoading }] = useDeleteOnePost();

  const handleDelete = () => {
    if (post) {
      deleteOnePost({
        variables: {
          where: { id: post.id },
        },
        onCompleted: (data) => {
          toast((t) => (
            <ToastMessage id={t.id} title="This post has been deleted." />
          ));
          handleClose();
          if (postId) {
            navigate(`/workspace/${workspaceSlug}/project/${projectId}`, {
              replace: true,
            });
          }
        },
        onError: (error) => {
          toast((t) => (
            <ToastMessage
              id={t.id}
              title={'There was an error deleting this post.'}
            >
              {error.message}
            </ToastMessage>
          ));
        },
      });
    }
  };

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          exit={{ y: 10, opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="fixed z-50 w-screen h-screen top-0 left-0 flex flex-col justify-center items-center"
        >
          <Modal
            open={show}
            data={{
              subtitle: 'You’re about to delete this post',
              snippet:
                'Are you sure you want to delete this post? You cannot undo this action.',
              btn1: 'Cancel',
              btn2: 'Delete',
              btn1Type: 'neutral',
              btn2Type: 'destructive',
            }}
            onBtn1Click={() => handleClose()}
            onBtn2Click={() => handleDelete()}
            loading={deleteOnePostLoading}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}
