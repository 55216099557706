import { AnimatePresence, motion } from 'framer-motion';
import toast from 'react-hot-toast';
import { useDeleteOneTaskComment } from 'src/api/comment/task-comment.service';
import ToastMessage from 'src/components/ToastMessage';
import Modal from 'src/components/ui/Modals/Modal';
import { IComment } from 'src/types/entities';

export default function DeleteTaskCommentModal(props: {
  show: boolean;
  handleClose: Function;
  comment: IComment | null;
}) {
  const { show, handleClose, comment } = props;
  const [deleteOneTaskComment, { loading }] = useDeleteOneTaskComment();

  const handleDelete = () => {
    if (comment) {
      deleteOneTaskComment({
        variables: {
          where: {
            id: comment.id,
          },
        },
        onCompleted: (data) => {
          toast((t) => (
            <ToastMessage id={t.id} title="This comment has been deleted." />
          ));
          handleClose();
        },
        onError: (error) => {
          toast((t) => (
            <ToastMessage
              id={t.id}
              title={'There was an error deleting this comment.'}
            />
          ));
        },
      });
    }
  };

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          exit={{ y: 10, opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="fixed z-50 w-screen h-screen top-0 left-0 flex flex-col justify-center items-center"
        >
          <Modal
            open={show}
            data={{
              subtitle: 'You’re about to delete this comment',
              snippet:
                'Are you sure you want to delete this comment? You cannot undo this action.',
              btn1: 'Cancel',
              btn2: 'Delete',
              btn1Type: 'neutral',
              btn2Type: 'destructive',
            }}
            onBtn1Click={() => handleClose()}
            onBtn2Click={() => handleDelete()}
            loading={loading}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}
