import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_ONE_TASK,
  DELETE_ONE_TASK,
  UPDATE_ONE_TASK,
} from 'src/lib/mutations';
import { TASKS } from 'src/lib/queries';
import { useApolloOptions } from '../api';

export function useTasks(args: object) {
  const options = useApolloOptions();

  return useQuery(TASKS, {
    ...options,
    fetchPolicy: 'cache-and-network' as const,
    ...args,
  });
}

export function useCreateOneTask() {
  const options = useApolloOptions();

  return useMutation(CREATE_ONE_TASK, {
    ...options,
    refetchQueries: [TASKS],
  });
}

export function useUpdateOneTask() {
  const options = useApolloOptions();

  return useMutation(UPDATE_ONE_TASK, {
    ...options,
    refetchQueries: [TASKS],
  });
}

export function useDeleteOneTask() {
  const options = useApolloOptions();

  return useMutation(DELETE_ONE_TASK, {
    ...options,
    refetchQueries: [TASKS],
  });
}
