import { RefObject, useEffect } from 'react';

type CallbackFunction = (event: MouseEvent) => void;

function useClickOutsideElement<T extends HTMLElement = HTMLElement>(
  callback: CallbackFunction,
  ref: RefObject<T> | null
): void {
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (ref?.current && !ref.current.contains(event.target as Node)) {
        callback(event);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [ref, callback]);
}

export default useClickOutsideElement;
