import { useMutation } from '@apollo/client';
import {
  CREATE_ONE_TASK_COMMENT,
  DELETE_ONE_TASK_COMMENT,
  UPDATE_ONE_TASK_COMMENT,
} from 'src/lib/mutations';
import { TASKS } from 'src/lib/queries';
import { useApolloOptions } from '../api';

export function useCreateOneTaskComment() {
  const options = useApolloOptions();

  return useMutation(CREATE_ONE_TASK_COMMENT, {
    ...options,
    refetchQueries: [TASKS],
  });
}

export function useUpdateOneTaskComment() {
  const options = useApolloOptions();

  return useMutation(UPDATE_ONE_TASK_COMMENT, {
    ...options,
    refetchQueries: [TASKS],
  });
}

export function useDeleteOneTaskComment() {
  const options = useApolloOptions();

  return useMutation(DELETE_ONE_TASK_COMMENT, {
    ...options,
    refetchQueries: [TASKS],
  });
}
