import { Menu, Popover, Transition } from '@headlessui/react';
import { useDebounce } from '@uidotdev/usehooks';
import classNames from 'classnames';
import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  CopyDuplicate,
  Link,
  MoreHorizontal,
  Search,
  TrashAltDeleteBin,
  XCloseDelete,
} from 'react-basicons';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Edit, Plus, Trash } from 'src/assets/icons';
import LinkLogo from 'src/components/LinkLogo';
import Modal from 'src/components/Modal';
import ToastMessage from 'src/components/ToastMessage';
import UrlInput from 'src/components/UrlInput';
import { fuzzySearchRegex, theme } from 'src/lib/helpers';
import { IProjectLink } from 'src/lib/types';
import { fixUrl } from 'src/lib/utils';
import Validator from 'validatorjs';
import Button from '../ui/Button/Button';
import { DropItem } from '../ui/DropGroup';
import Links from '../ui/Links';
import StatusTag from '../ui/tags/StatusTags';

interface ProjectLinksSelectProps {
  projectId?: string;
  value: IProjectLink[];
  onChange: (links: IProjectLink[]) => void;
  readOnly?: boolean;
}

type FormData = {
  url: string;
  label: string;
};

const initialFormData = {
  url: '',
  label: '',
};

const ProjectLinksSelect: React.FC<ProjectLinksSelectProps> = ({
  value,
  onChange,
  projectId,
  readOnly = true,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: initialFormData,
  });
  const formData = watch();
  const [formErrors, setFormErrors] =
    useState<Validator.ValidationErrors | null>(null); // State for form validation errors

  const [searchTerm, setSearchTerm] = useState(''); // State for search ter

  const [createLink, setCreateLink] = useState(!projectId); // State to determine if a new link should be created
  const [openLinkIndex, setOpenLinkIndex] = useState<number | null>(null);
  const [editLink, setEditLink] = useState<boolean>(false);
  const [editLinkIndex, setEditLinkIndex] = useState<number | null>(null);

  const modalRef = useRef<HTMLDivElement>(null);

  const debouncedSearchTerm = useDebounce(searchTerm, 300); // Debounced search term to reduce frequent updates
  const regex = fuzzySearchRegex(debouncedSearchTerm); // Regex for fuzzy search

  const handleSearchTermChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const searchTermFilter = (link: IProjectLink) => {
    if (debouncedSearchTerm.length) {
      return regex.test(link.label) || regex.test(link.url);
    }
    return true;
  };

  const filteredLinks = value.filter(searchTermFilter);
  const noSearchResults =
    filteredLinks.length === 0 && debouncedSearchTerm.length > 0;

  // Handler to save the new link
  const handleSave = (close: () => void) => () => {
    const validation = new Validator(formData, {
      url: 'required|url',
      label: 'required',
    });

    if (validation.passes()) {
      onChange([...value, formData]);
      reset(initialFormData);
      close();
    } else {
      setFormErrors(validation.errors.all());
    }
  };

  const handleEditSave = (close: () => void) => () => {
    const validation = new Validator(formData, {
      url: 'required|url',
      label: 'required',
    });

    if (validation.passes()) {
      setFormErrors(null);

      const newLinks = value.map((link, index) =>
        index === editLinkIndex ? formData : link
      );
      onChange(newLinks);
      reset(initialFormData);
      setOpenLinkIndex(null);
      close();
    } else {
      setFormErrors(validation.errors.all());
    }
  };

  const handleDelete = (delIndex: number) => {
    const newLink = value.filter((_, index) => index !== delIndex);
    onChange([...newLink]);
  };

  // Handler to remove a link
  const removeLink = (index: number) => () => {
    const updatedLinks = [...value];
    updatedLinks.splice(index, 1);
    onChange(updatedLinks);
  };

  // Handler to copy link URL to clipboard
  const copyLink = (url: string) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast((t) => (
          <ToastMessage id={t.id} title={'Link URL is copied to clipboard.'} />
        ));
      })
      .catch(() => {
        toast((t) => (
          <ToastMessage id={t.id} title={'Error copying text to clipboard.'} />
        ));
      });
  };

  useEffect(() => {
    if (value.length === 0) {
      setCreateLink(true);
    }
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setOpenLinkIndex(null);
      }
    };

    if (openLinkIndex !== null) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openLinkIndex]);

  // popover modal that shows when you click the three dots icon to edit or modify links
  const modifyLink = (index: number) => (
    <div
      className="drop-group absolute z-10 left-0 mt-9 w-full max-w-56 top-0"
      ref={modalRef}
    >
      <div className="px-1 py-1 ">
        <div>
          <Popover>
            <Popover.Button className={'w-full justify-start'}>
              <DropItem
                item={{
                  name: `Edit`,
                  icon: <Edit />,
                }}
                onItemClick={() => {
                  setEditLinkIndex(index);
                  setEditLink(true);
                  reset({
                    url: value?.[index].url,
                    label: value?.[index].label,
                  });
                }}
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={`fixed top-32 translate-x-[5%] md:h-max md:top-[-320px] md:absolute left-0 z-10 bottom-0 mb-10 max-w-full space-y-4 ${
                  createLink && !projectId
                    ? 'min-w-full md:min-w-[528px]'
                    : 'md:min-w-64'
                } bg-[var(--backgrounds-dropdowns)] shadow-[0px_20px_25px_0px_rgba(42,_42,_42,_0.20)] rounded-xl`}
              >
                {({ close }) => (
                  <div className="p-4">{renderCreateProjectLink(close)}</div>
                )}
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>
        <div>
          <button onClick={() => handleDelete(index)}>
            <DropItem
              item={{
                name: `Delete`,
                icon: <Trash />,
              }}
              onItemClick={() => setOpenLinkIndex(null)}
            />
          </button>
        </div>
      </div>
    </div>
  );

  // Render function for the main button
  const renderButton = () => {
    if (value?.length) {
      return projectId ? (
        <ul className="space-y-3">
          {value?.map((link) => {
            const fixedUrl = fixUrl(link);
            return (
              <li key={link.id} className="flex items-center space-x-2">
                <Links
                  href={fixedUrl.url} // Use the fixed URL here
                  icon={<LinkLogo link={fixedUrl} />}
                  value={fixedUrl.label} // Use the fixed label here
                  onClick={() => {
                    copyLink(fixedUrl.url);
                  }}
                />
              </li>
            );
          })}
        </ul>
      ) : (
        <ul className="relative flex flex-col flex-wrap items-start w-full">
          {value?.map((link, index) => (
            <li
              key={index}
              className="relative flex w-full items-center space-x-2"
            >
              <Links
                active={Boolean(index === openLinkIndex)}
                showCopy={false}
                showMore={true}
                href={link.url}
                icon={<LinkLogo link={link} />}
                value={link.label}
                onClick={() => setOpenLinkIndex(index)}
              />
              {Boolean(index === openLinkIndex) && modifyLink(index)}
            </li>
          ))}
        </ul>
      );
    }

    // empty states that shows the create link btn on edit and create also show the not links yet on the side panel

    return (
      readOnly && (
        <div className="px-1.5">
          <StatusTag value="No resources yet" color="default" size="medium" />
        </div>
      )
      // : (
      //   <div className='button ghost small justify-start w-fit transition !pl-1.5'>
      //     <Plus />
      //     <span className='text-[var(--text-on-bg)]'>Add links</span>
      //   </div>
      // )
    );
  };

  const renderCreateProjectLink = (close: () => void) => (
    <div className="flex flex-col gap-8">
      <p className="text-body-large medium text-[var(--text-default)]">
        Add a link
      </p>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          {/* <LinkedInput value={formData.url} feedback={''} label='Enter URL' onChange={handleInputChange('url')} /> */}
          <label
            htmlFor="project-link-label"
            className="text-[var(--text-default)]"
          >
            Enter URL
          </label>
          <UrlInput
            id="project-link-url"
            value={formData.url}
            onChange={(value) => {
              setValue('url', value);
              trigger('url');
            }}
          />
          {errors.url && (
            <div className="text-xs text-red-500/75 font-medium mt-1">
              {errors.url && 'URL is required'}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor="project-link-label"
            className="text-[var(--text-default)]"
          >
            Label
          </label>
          <input
            id="project-link-label"
            value={formData.label}
            onChange={(e) => {
              setValue('label', e.target.value);
              trigger('label');
            }}
            type="text"
            className="input w-full"
          />
          {errors.label && (
            <div className="text-xs text-red-500/75 font-medium mt-1">
              {errors.label.message}
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center gap-3 justify-end">
        <Button
          size="medium"
          btnType="neutral"
          icon="left"
          className="w-fit"
          value="Cancel"
          onClick={() => {
            reset(initialFormData);
            close();
          }}
        />
        {editLink ? (
          <Button
            size="medium"
            btnType="primary"
            icon="left"
            className="w-fit"
            value="Save"
            onClick={handleSubmit(handleEditSave(close))}
          />
        ) : (
          <Button
            size="medium"
            btnType="primary"
            icon="left"
            className="w-fit"
            value="Add link"
            onClick={handleSubmit(handleSave(close))}
          />
        )}
      </div>
    </div>
  );

  const renderCreateProjectLinkModal = Boolean(projectId) && (
    <Modal
      size="small"
      show={createLink}
      handleClose={() => setCreateLink(false)}
      bodyClassName="space-y-8"
    >
      <header className="text-grey-700 font-medium">Add link</header>
      {renderCreateProjectLink(() => setCreateLink(false))}
    </Modal>
  );

  return (
    <Popover
      className={`relative ${
        (Boolean(projectId) && readOnly) || value.length ? '' : '-ml-1'
      } `}
    >
      {({ open, close }) => (
        <>
          {renderButton()}
          <Popover.Button
            className={`${open ? '' : ''} justify-start`}
            onClick={() => reset(initialFormData)}
          >
            {Boolean(projectId) || (
              <div className="button ghost small justify-start w-fit transition !pl-1.5">
                <Plus />
                <span className="text-[var(--text-on-bg)]  text-body-micro font-medium">
                  Add new link
                </span>
              </div>
            )}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={`fixed top-32 translate-x-[5%] md:h-max md:top-[-320px] md:absolute left-0 z-10 bottom-0 mb-10 max-w-full space-y-4 ${
                createLink && !projectId
                  ? 'min-w-full md:min-w-[528px]'
                  : 'md:min-w-64'
              }`}
            >
              <div className="w-[90%] md:w-auto drop-group">
                <div className="sticky top-0 z-10 bg-white rounded-t-lg">
                  {!readOnly && (!createLink || projectId) && (
                    <div className="relative border-b border-grey-100">
                      <label
                        htmlFor="link-search"
                        className="absolute left-0 inset-y-0 px-3 grid place-content-center"
                      >
                        <Search size={12} />
                      </label>
                      <input
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                        id="link-search"
                        type="text"
                        className="input-clear py-3 !pl-8 !pr-3 w-full text-sm font-light"
                        placeholder="Search"
                      />
                      <div className="absolute right-0 inset-y-0 px-3 grid place-content-center">
                        <Button
                          size="small"
                          btnType="ghost"
                          icon="left"
                          value="Add link"
                          onClick={() => setCreateLink(true)}
                        >
                          Add
                        </Button>
                      </div>
                    </div>
                  )}
                  {!noSearchResults && (!createLink || projectId) && (
                    <div className="py-2 px-4 text-xs text-grey-300">Links</div>
                  )}
                </div>
                {noSearchResults && (!createLink || projectId) && (
                  <p className="text-grey-300 text-xs text-center p-4">
                    No search results for “
                    <span className="font-semibold">{debouncedSearchTerm}</span>
                    ”
                  </p>
                )}
                {createLink && !projectId && (
                  <div className="p-4">{renderCreateProjectLink(close)}</div>
                )}
                {renderCreateProjectLinkModal}
                {(!createLink || projectId) && filteredLinks.length > 0 && (
                  <ul className="p-1">
                    {filteredLinks.map((link, index) => (
                      <li key={index}>
                        <div
                          className={classNames(
                            'group rounded-md hover:bg-grey-50 px-3 flex items-center space-x-2 py-2',
                            { 'cursor-pointer': !readOnly }
                          )}
                        >
                          {projectId ? (
                            <LinkLogo link={link} />
                          ) : (
                            <Link size={16} />
                          )}
                          <div className="flex-1 text-sm text-grey-900">
                            {link.label}
                          </div>
                          {Boolean(projectId) && readOnly && (
                            <button
                              type="button"
                              onClick={() => copyLink(link.url)}
                              className="pointer-events-none group-hover:pointer-events-auto opacity-0 group-hover:opacity-100"
                            >
                              <CopyDuplicate
                                size={16}
                                weight={2}
                                color={(theme.textColor.grey as any)['300']}
                              />
                            </button>
                          )}
                          {Boolean(projectId) && !readOnly && (
                            <Menu
                              as="div"
                              className="pointer-events-none group-hover:pointer-events-auto opacity-0 group-hover:opacity-100 relative inline-block text-left"
                            >
                              {({ open }) => (
                                <>
                                  <div>
                                    <Menu.Button
                                      className={`${
                                        open ? 'bg-grey-75' : ''
                                      } hover:bg-grey-75 aspect-square w-5 rounded grid place-content-center`}
                                    >
                                      <MoreHorizontal
                                        color={
                                          (theme.textColor.grey as any)['300']
                                        }
                                        size={16}
                                        weight={2}
                                      />
                                    </Menu.Button>
                                  </div>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="absolute z-10 right-0 mt-2 w-48 origin-top-right divide-y divide-grey-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                      <div className="px-1 py-1">
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              onClick={() => copyLink(link.url)}
                                              className="hover:bg-grey-75 flex w-full items-center rounded-md p-2 text-sm space-x-2 text-grey-400"
                                            >
                                              <CopyDuplicate
                                                size={16}
                                                color={
                                                  (theme.textColor.grey as any)[
                                                    '400'
                                                  ]
                                                }
                                                weight={2}
                                              />
                                              <span>Copy link</span>
                                            </button>
                                          )}
                                        </Menu.Item>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              onClick={removeLink(index)}
                                              className="hover:bg-negative-50 flex w-full items-center rounded-md p-2 text-sm space-x-2 text-negative-700"
                                            >
                                              <TrashAltDeleteBin
                                                size={16}
                                                color={
                                                  (
                                                    theme.textColor
                                                      .negative as any
                                                  )['700']
                                                }
                                                weight={2}
                                              />
                                              <span>Delete link</span>
                                            </button>
                                          )}
                                        </Menu.Item>
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </>
                              )}
                            </Menu>
                          )}
                          {Boolean(projectId) || (
                            <button
                              type="button"
                              onClick={removeLink(index)}
                              className="pointer-events-none group-hover:pointer-events-auto opacity-0 group-hover:opacity-100"
                            >
                              <XCloseDelete size={16} weight={2} />
                            </button>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default ProjectLinksSelect;
