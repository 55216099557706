import { ArrowBack } from 'src/assets/icons';
import useMobile from 'src/hooks/useMobile';
import SectionWrap from '../common/SectionWrap';
import IconButton from '../ui/Button/IconButton';
import ProjectMetaDetails from './ProjectMetaDetails';

interface ProjectSidePanelProps {
  formData: any;
  handleInputChange: any;
  workspaceSlug: string;
  projectId: string;
  project: any;
  readOnly: boolean;
  state: any;
  setState: any;
}

const ProjectSidePanel = (props: ProjectSidePanelProps) => {
  const {
    formData,
    handleInputChange,
    workspaceSlug,
    projectId,
    project,
    readOnly,
    state,
    setState,
  } = props;

  const isMobile = useMobile();
  return (
    <aside
      className={`absolute z-10 md:z-0 md:sticky right-0 h-screen md:h-[calc(100vh-16px)] md:!translate-x-0 top-0 md:max-w-[406px] w-full transition-all lg:block bg-[rgba(7,7,7,0.5)] md:bg-transparent ${
        isMobile && state.openPanel ? 'slide-in-panel' : 'close-panel'
      }`}
    >
      <SectionWrap className="sticky top-0 h-full p-5 overflow-y-auto max-w-[92%] md:max-w-full md:w-full ml-auto">
        <div className="mb-8 md:hidden">
          <IconButton
            icon={<ArrowBack />}
            size={'small'}
            type={'ghost'}
            onClick={() => setState({ ...state, openPanel: false })}
          />
        </div>

        {workspaceSlug && projectId && (
          <ProjectMetaDetails
            value={formData}
            onChange={handleInputChange}
            workspaceSlug={workspaceSlug}
            projectId={projectId}
            project={project}
            readOnly={readOnly}
          />
        )}
      </SectionWrap>
    </aside>
  );
};

export default ProjectSidePanel;
