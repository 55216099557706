import { useLocalStorage } from '@uidotdev/usehooks';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { useUpdateOnePost } from 'src/api/post/post.service';
import DeletePostCommentModal from 'src/components/projects/comments/posts/DeletePostCommentModal';
import ToastMessage from 'src/components/ToastMessage';
import Avatar from 'src/components/ui/Avatar/Avatar';
import Button from 'src/components/ui/Button/Button';
import Timestamp from 'src/components/ui/TimeStamp';
import { IComment } from 'src/types/entities';
import PostCommentMenu from './PostCommentMenu';

interface ReplyCommentBodyProps {
  comment: IComment;
}

function ReplyCommentBody({ comment }: ReplyCommentBodyProps) {
  const [userId] = useLocalStorage('userId', undefined);
  const isCommentOwner = userId === comment.author.id;
  const [editComment, setEditComment] = useState<string | undefined>(undefined);
  const [deleteComment, setDeleteComment] = useState<IComment | null>(null);
  const [updateOnePost, { loading: loadingCommentReply }] = useUpdateOnePost();

  const [searchParams] = useSearchParams();
  const commentId = searchParams.get('commentId');
  const componentRef = useRef<HTMLDivElement | null>(null);
  const isFocusedComment = commentId === comment.id;

  useEffect(() => {
    if (isFocusedComment && componentRef.current instanceof HTMLElement) {
      const element = componentRef.current;

      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      element.classList.add('bg-warning-50');
      setTimeout(() => {
        element.classList.remove('bg-warning-50');
      }, 2000);
    }
  }, [isFocusedComment]);

  const handleCopy = () => {
    const origin = window.location.origin;
    const post = comment.post;
    const project = post.project;
    //@ts-ignore
    const workspace = project.workspace;
    //@ts-ignore
    const link = `${origin}/workspace/${workspace.slug}/project/${project.id}/post/${post.id}?commentId=${comment.id}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title={'Link to comment is copied to clipboard.'}
          />
        ));
      })
      .catch((err) => {
        toast((t) => (
          <ToastMessage id={t.id} title={'Error copying link to clipboard.'} />
        ));
      });
  };

  const handleSave = () => {
    updateOnePost({
      variables: {
        data: {
          comments: {
            update: [
              {
                data: {
                  comment: { set: editComment },
                },
                where: { id: comment.id },
              },
            ],
          },
        },
        where: { id: comment.post.id },
      },
      onCompleted: () => {
        setEditComment(undefined);
      },
      onError: (error) => {
        toast((t) => <ToastMessage id={t.id} title={error.message} />);
      },
    });
  };

  return (
    <div
      ref={componentRef}
      className="duration-1000 flex items-start gap-y-2 flex-col w-full"
    >
      <div className="flex items-center space-x-6 w-full">
        <div className="flex-1 flex gap-1.5 items-center">
          <Avatar
            alt={comment.author.detail.fullName}
            src={comment.author.detail.avatar?.url}
            size={20}
            name={comment.author.detail.fullName}
          />
          <div className="flex items-center gap-1.5">
            <div className="text-body-micro medium text-[var(--text-default)]">
              {comment.author.detail.fullName}
            </div>
            <svg
              width="5"
              height="4"
              viewBox="0 0 5 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 3.16477C2.21354 3.16477 1.95194 3.09493 1.7152 2.95526C1.47846 2.81321 1.28906 2.62382 1.14702 2.38707C1.00734 2.15033 0.9375 1.88873 0.9375 1.60227C0.9375 1.31345 1.00734 1.05185 1.14702 0.817472C1.28906 0.580729 1.47846 0.392519 1.7152 0.252841C1.95194 0.110795 2.21354 0.0397725 2.5 0.0397725C2.78883 0.0397725 3.05043 0.110795 3.2848 0.252841C3.52154 0.392519 3.70975 0.580729 3.84943 0.817472C3.99148 1.05185 4.0625 1.31345 4.0625 1.60227C4.0625 1.88873 3.99148 2.15033 3.84943 2.38707C3.70975 2.62382 3.52154 2.81321 3.2848 2.95526C3.05043 3.09493 2.78883 3.16477 2.5 3.16477Z"
                fill="#8D8D8D"
              />
            </svg>

            <div className="text-caption regular text-[var(--text-neutral)]">
              <Timestamp createdAt={comment.createdAt} />
            </div>
          </div>
        </div>

        <PostCommentMenu
          canEdit={isCommentOwner}
          canDelete={isCommentOwner}
          canArchive={isCommentOwner}
          handleCopy={handleCopy}
          handleEdit={() => setEditComment(comment.comment)}
          handleDelete={() => setDeleteComment(comment)}
          type={'comment'}
        />
      </div>

      {Boolean(editComment) || (
        <p className="text-body-micro regular text-[var(--text-default)]  w-full">
          {comment.comment}
        </p>
      )}

      {Boolean(editComment) && (
        <div className="space-y-4 w-full">
          <TextareaAutosize
            autoFocus
            value={editComment}
            onChange={(e) => setEditComment(e.target.value)}
            placeholder="Edit this comment"
            disabled={loadingCommentReply}
            className="resize-none w-full input text-sm shadow-none mt-2"
          />

          <div className="flex items-center justify-end space-x-3">
            <Button
              onClick={() => setEditComment(undefined)}
              type="button"
              className={'w-fit'}
              size="small"
              value={'Cancel'}
              btnType={'neutral'}
            />
            <Button
              onClick={handleSave}
              type="button"
              className={'w-fit'}
              size="small"
              loading={loadingCommentReply}
              disabled={loadingCommentReply || editComment === comment.comment}
              value={'Save'}
              btnType={'primary'}
            />
          </div>
        </div>
      )}

      <DeletePostCommentModal
        comment={deleteComment}
        show={Boolean(deleteComment)}
        handleClose={() => setDeleteComment(null)}
      />
    </div>
  );
}

export default ReplyCommentBody;
