import { Plus } from 'src/assets/icons';
import { useTaskContext } from 'src/context/taskContext';
import { ITask, TTaskStatus } from 'src/types/entities';
import { tv } from 'tailwind-variants';
import TaskCard from './TaskCard';

interface TaskBoardProps {
  tasks: ITask[];
  taskStatus: TTaskStatus;
  onCreateTask: () => void;
}

export const StatusLabel = {
  NOT_STARTED: 'Not Started',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
};

const TaskBoard = (props: TaskBoardProps) => {
  const { tasks, taskStatus, onCreateTask } = props;
  const { openTaskDetails } = useTaskContext();

  return (
    <div className="w-full h-full bg-[var(--backgrounds-canvas)] rounded-[8px] p-2 gap-y-3 flex flex-col items-center">
      <div className="w-full h-[40px] flex justify-between items-center">
        <div className="flex justify-start items-center gap-x-2 w-max pl-3">
          <div className={taskBoardStatusStyles({ type: taskStatus })} />
          <h3 className="whitespace-nowrap text-body-caps text-[var(--text-neutral)] font-bold">
            {StatusLabel[taskStatus]}
          </h3>
          <div className="flex items-center justify-center w-[20px] h-[20px] bg-[var(--backgrounds-default)] rounded-[6px] text-body-micro text-[var(--text-on-bg)]">
            {tasks.length}
          </div>
        </div>
        <button
          onClick={onCreateTask}
          className="w-[28px] h-[28px] rounded-1 text-body-small regular text-[var(--text-disabled)]"
        >
          <Plus className="h-4 w-4" />
        </button>
      </div>

      {tasks.map((task: ITask) => (
        <TaskCard
          key={task.id}
          task={task}
          cardType={'listView'}
          onClick={() => openTaskDetails(task)}
        />
      ))}
    </div>
  );
};

const taskBoardStatusStyles = tv({
  base: 'h-[10px] w-[10px]  rounded-full',
  variants: {
    type: {
      NOT_STARTED: 'bg-[var(--icon-primary)]',
      IN_PROGRESS: 'bg-[var(--color-warning-500)]',
      COMPLETED: 'bg-[var(--color-success-500)]',
    },
  },
  defaultVariants: {
    type: 'NOT_STARTED',
  },
});

export default TaskBoard;
