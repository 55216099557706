import { Outlet } from 'react-router-dom';
import SettingSideNav from 'src/components/sideNav/SettingSideNav';
import SideNav from 'src/components/sideNav/SideNav';
import { WorkspaceContextProvider } from 'src/context/workspaceContext';

export default function SettingsLayout() {
  return (
    <WorkspaceContextProvider>
      <div className="flex fixed min-h-screen w-full">
        <SideNav>
          <SettingSideNav />
        </SideNav>
        <div className="outlet">
          <main className="flex flex-col items-center my-6 mx-8 relative">
            <Outlet />
          </main>
        </div>
      </div>
    </WorkspaceContextProvider>
  );
}
