import classNames from 'classnames';
import { Fragment } from 'react';
import { IComment } from 'src/types/entities';
import PostCommentBody from './PostCommentBody';

function PostCommentReplies(props: {
  comment: IComment;
  onClose: Function;
  show: boolean;
}) {
  const { comment, onClose, show } = props;

  return (
    <Fragment>
      {show && (
        <div onClick={() => onClose()} className="fixed inset-0 z-10"></div>
      )}
      <div
        className={classNames(
          'overflow-auto fixed inset-y-0 right-0 w-96 lg:w-[29rem] bg-white z-10 shadow-2xl p-6 py-10 space-y-6 transition',
          show
            ? 'translate-x-0 opacity-100'
            : 'translate-x-96 lg:translate-x-[29rem] opacity-0 pointer-events-none'
        )}
      >
        {comment.responses.map((response) => (
          <PostCommentBody key={response.id} comment={response} />
        ))}
      </div>
    </Fragment>
  );
}

export default PostCommentReplies;
