import { useState } from 'react';
import toast from 'react-hot-toast';
import { ArrowLink, MegaPhone, UserCircle, WorkStream } from 'src/assets/icons';
import ToastMessage from 'src/components/ToastMessage';
import Avatar from 'src/components/ui/Avatar/Avatar';
import { usePostsContext } from 'src/context/postContext';
import useProjectFilters from 'src/hooks/project/useProjectFilters';
import useMobile from 'src/hooks/useMobile';
import { transformToTitleCase } from 'src/lib/helpers';
import { IPost, IProjectTopic, IUser, PostType } from 'src/lib/types';
import SectionWrap from '../../common/SectionWrap';
import Button from '../../ui/Button/Button';
import HorizontalDivider from '../../ui/HorizontalDivider';
import ProjectFilterDropdown from '../filter/ProjectFilterDropdown';
import DeletePostModal from './DeletePostModal';
import PostItem from './PostItem';
import PostModal from './PostModal';

interface IState {
  editPost?: {
    show: boolean;
    post: IPost | undefined;
  };
  deletePost?: {
    show: boolean;
    post: IPost | undefined;
  };
}

interface IPostArena {
  handleCreatePost: Function;
  workspaceSlug: string;
  heightHeadingContainerRef: number;
  collaborators?: IUser[];
  workStreams?: IProjectTopic[];
}

const postTypes = Object.values(PostType);

function PostsArena(props: IPostArena) {
  const {
    handleCreatePost,
    workspaceSlug,
    heightHeadingContainerRef,
    collaborators = [],
    workStreams = [],
  } = props;

  const [state, setState] = useState<IState>({
    editPost: {
      show: false,
      post: undefined,
    },
    deletePost: undefined,
  });

  const isMobile = useMobile();
  const { posts } = usePostsContext();

  const {
    filteredItems: filteredPosts,
    filterCriteria,
    setFilterCriteria,
  } = useProjectFilters<IPost>(posts || [], { itemType: 'post' as const });
  // console.log('posts', posts);

  const handleCopyPost = (post: IPost) => () => {
    const origin = window.location.origin;
    const project = post.project;
    const workspace = project.workspace;
    const link = `${origin}/workspace/${workspace.slug}/project/${project.id}/posts/${post.id}`;

    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast(
          (t) => (
            <ToastMessage
              id={t.id}
              title={'Link to post is copied to clipboard.'}
            />
          ),
          { duration: 50000 }
        );
      })
      .catch((err) => {
        toast((t) => (
          <ToastMessage id={t.id} title={'Error copying text to clipboard.'} />
        ));
      });
  };

  if (!!posts?.length) {
    return (
      <SectionWrap
        style={{
          height: !isMobile
            ? `calc(100vh - ${heightHeadingContainerRef}px)`
            : '100%',
        }}
        className="flex flex-col md:max-h-full !rounded-[0_0_8px_8px]"
      >
        <div className="w-full flex justify-between mt-6 pb-2 items-start px-6">
          <div className="w-max flex items-start gap-x-2">
            <ProjectFilterDropdown
              value={filterCriteria.filteredWorkstreams}
              onChange={(newWorkstreams: string[]) =>
                setFilterCriteria({
                  ...filterCriteria,
                  filteredWorkstreams: newWorkstreams,
                })
              }
              icon={<WorkStream className="w-3 h-3" />}
              label="Workstreams"
              options={
                workStreams.map((workStream: any) => ({
                  label: workStream.name,
                  value: workStream.id,
                })) || []
              }
            />
            <ProjectFilterDropdown
              value={filterCriteria.filteredTypes || []}
              onChange={(newTypes: string[]) =>
                setFilterCriteria({
                  ...filterCriteria,
                  filteredTypes: newTypes,
                })
              }
              icon={<MegaPhone className="w-3 h-3" />}
              label="Type"
              options={
                postTypes.map((type: string) => ({
                  label: transformToTitleCase(type),
                  value: type,
                })) || []
              }
            />
            <ProjectFilterDropdown
              value={filterCriteria.filteredMembers}
              onChange={(newMembers: string[]) =>
                setFilterCriteria({
                  ...filterCriteria,
                  filteredMembers: newMembers,
                })
              }
              className="min-w-[250px]"
              icon={<UserCircle className="w-3 h-3" />}
              label="Members"
              options={
                collaborators?.map((collaborator) => ({
                  label: collaborator.detail.fullName,
                  value: collaborator.id,
                  other: collaborator,
                })) || []
              }
              customOptionItem={(option) => (
                <div className="flex items-center gap-x-2 py-[2.5px]">
                  <Avatar name={option.label as string} size={28} />
                  <div className="flex-1">
                    <div className="text-body-small medium text-[var(--text-default)]">
                      {option.label}
                    </div>
                    <div className="text-body-micro regular text-[var(--text-neutral)]">
                      {transformToTitleCase(option.other.detail.position)}
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
          <div
            className="min-w-[57px] p-[1.5px] min-h-[27px] invisible"
            aria-label="invsible block"
          />
        </div>

        {filteredPosts.length > 0 ? (
          <div className="h-full overflow-y-auto mb-[92px]">
            <ul className="flex flex-col pb-[100px] flex-grow">
              {posts.map((post: IPost, index: number) => (
                <li key={`${post.id}-${index}`} className="w-full">
                  <PostItem
                    post={post}
                    handleCopy={handleCopyPost(post)}
                    handleEdit={() =>
                      setState({
                        ...state,
                        editPost: { show: true, post },
                      })
                    }
                    handleDelete={() =>
                      setState({
                        ...state,
                        deletePost: { show: true, post },
                      })
                    }
                  />
                  <HorizontalDivider />
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-full pb-[92px]">
            <MegaPhone className="w-8 h-8" />
            <div className="space-y-2 flex flex-col items-center">
              <div className="text-heading-6 medium text-[var(--text-default)]">
                No posts found
              </div>
              <p className="text-body-small regular text-[var(--text-neutral)]">
                No posts found with the selected filters.
              </p>
            </div>
          </div>
        )}

        <PostModal
          initialPost={state.editPost?.post}
          show={Boolean(state.editPost?.show)}
          handleClose={() =>
            setState({
              ...state,
              editPost: {
                show: false,
                post: undefined,
              },
            })
          }
          collaborators={collaborators}
        />

        <DeletePostModal
          post={state.deletePost?.post}
          show={Boolean(state.deletePost?.show)}
          handleClose={() =>
            setState({
              ...state,
              deletePost: {
                show: false,
                post: undefined,
              },
            })
          }
        />
      </SectionWrap>
    );
  }

  return (
    <SectionWrap className="h-full flex flex-col gap-4 !rounded-[0_0_8px_8px]">
      <div className="text-center flex justify-center flex-col items-center space-y-6 h-full pb-[92px]">
        <MegaPhone className="w-8 h-8" />
        <div className="space-y-2 flex flex-col">
          <div className="text-heading-6 medium text-[var(--text-default)]">
            No posts  yet
          </div>
          <p className="max-w-64 text-body-small regular text-[var(--text-neutral)]">
          Start the conversation with a question or an update on ongoing work.
          </p>
        </div>
        <Button
          size="medium"
          btnType="primary"
          icon="right"
          value={'Write a post'}
          className={'w-auto'}
          onClick={() => handleCreatePost()}
        >
          <ArrowLink />
        </Button>
      </div>
    </SectionWrap>
  );
}

export default PostsArena;
