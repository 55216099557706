import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useUpdateOneUserAvatar } from 'src/api/user/useUpdateOneUserAvatar';
import { Image, Trash } from 'src/assets/icons';
import ToastMessage from 'src/components/ToastMessage';
import Avatar from 'src/components/ui/Avatar/Avatar';
import DropGroup from 'src/components/ui/DropGroup';
import { useImageUploader } from 'src/hooks/useImageUploader';

interface ProfilePictureProps {
  name: string;
  userId: string;
  profileImageUrl: string | undefined;
  setProfileImageUrl: (event: string | undefined) => void;
}

interface DataItem {
  name: string;
  value: 'change' | 'del';
  icon: JSX.Element;
}

export default function ProfilePicture({
  name,
  profileImageUrl,
  userId,
  setProfileImageUrl,
}: ProfilePictureProps) {
  const [UpdateOneUserDetail] = useUpdateOneUserAvatar();
  const { images, handleFileChange, removeImage } = useImageUploader({
    maxFiles: 1,
  });

  const [toggleProfileImageModal, setToggleProfileImageModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const data: DataItem[] = [
    {
      name: profileImageUrl ? 'Change image' : 'Add profile image',
      value: 'change',
      icon: <Image />,
    },
    ...(profileImageUrl
      ? ([
          {
            name: 'Remove picture',
            value: 'del',
            icon: <Trash />,
          },
        ] as DataItem[])
      : []),
  ];

  function handleDropGroupEvent(item: DataItem) {
    if (item.value === 'change') {
      document.getElementById('profile-image-upload')?.click();
    } else if (item.value === 'del') {
      delUserAvatar();
    }
    setToggleProfileImageModal(false);
  }

  function handleImageUpload(event: React.ChangeEvent<HTMLInputElement>) {
    handleFileChange(event.target.files);
    if (event.target) event.target.value = '';
    setToggleProfileImageModal(false);
  }

  async function updateUserAvatar(uploadedImage: any) {
    setLoading(true);
    try {
      const { data } = await UpdateOneUserDetail({
        variables: {
          data: {
            avatar: {
              set: {
                filename: uploadedImage.file.name,
                mimetype: uploadedImage.file.type,
                url: uploadedImage.dataURL,
              },
            },
          },
          where: {
            id: userId,
          },
        },
      });
      setProfileImageUrl(data.updateOneUserDetail.detail.avatar?.url);
      toast((t) => (
        <ToastMessage id={t.id} title="Profile image updated successfully" />
      ));
    } catch (error) {
      toast((t) => (
        <ToastMessage id={t.id} title={`Error updating profile image`} />
      ));
    } finally {
      setLoading(false);
    }
  }

  async function delUserAvatar() {
    setLoading(true);
    try {
      const { data } = await UpdateOneUserDetail({
        variables: {
          data: {
            avatar: {
              set: {
                filename: '',
                mimetype: '',
                url: '',
              },
            },
          },
          where: {
            id: userId,
          },
        },
      });
      setProfileImageUrl(data.updateOneUserDetail.detail.avatar?.url);
      removeImage(0);
      toast((t) => (
        <ToastMessage id={t.id} title="Profile image removed successfully" />
      ));
    } catch (error) {
      toast((t) => (
        <ToastMessage id={t.id} title="Error removing profile image" />
      ));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (images.length > 0 && !images[0].uploading && !images[0].error) {
      updateUserAvatar(images[0]);
    }
  }, [images]);

  return (
    <div className="profile relative">
      <Avatar
        name={name}
        src={images.length > 0 ? images[0].dataURL : profileImageUrl}
        loading={loading || (images.length > 0 && images[0].uploading)}
        alt={`${name} profile picture`}
        size={128}
        onClick={() => {
          setToggleProfileImageModal(!toggleProfileImageModal);
        }}
      />
      <div className="absolute top-24 right-20">
        <DropGroup
          open={toggleProfileImageModal}
          data={data}
          style={{ width: '200px' }}
          onClick={handleDropGroupEvent}
          clickOutside={() => {
            setToggleProfileImageModal(false);
          }}
        />
        <input
          id="profile-image-upload"
          className="hidden"
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
        />
      </div>
    </div>
  );
}
