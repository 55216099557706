import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { IUser } from 'src/lib/types';
import tailwindConfig from 'src/tailwind.config';
import { IComment, TaskStatus } from 'src/types/entities';
import resolveConfig from 'tailwindcss/resolveConfig';
import { avatarColorClasses } from './consts';
import { ProjectStatus } from './types';

export const getCurrentTheme = (
  theme: 'light' | 'dark' | 'system'
): 'light' | 'dark' => {
  if (theme === 'system') {
    const prefersDarkScheme = window.matchMedia(
      '(prefers-color-scheme: dark)'
    ).matches;
    return prefersDarkScheme ? 'dark' : 'light';
  }
  return theme;
};

export function transformToTitleCase(input: string): string {
  // Ensure the input is not empty
  if (!input) {
    return input;
  }

  // Replace underscores with spaces, capitalize each word
  return input
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

type ColorObject = {
  background: string;
  color: 'white' | 'black';
};

const isLightColor = (hexColor: string): boolean => {
  const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
  if (!rgb) {
    throw new Error('Invalid hex color');
  }

  const luminance =
    0.299 * parseInt(rgb[1], 16) +
    0.587 * parseInt(rgb[2], 16) +
    0.114 * parseInt(rgb[3], 16);

  return luminance > 128;
};

const generateRandomColor = (seed: string): string => {
  // Use a deterministic seed for color generation
  const seedValue = seed
    .split('')
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return `#${(seedValue * 2654435761).toString(16).slice(0, 6)}`;
};

export const getColorObject = (input: string): ColorObject => {
  const randomColor = generateRandomColor(input);
  const textColor = isLightColor(randomColor) ? 'black' : 'white';

  return {
    background: randomColor,
    color: textColor,
  };
};

export const stopPropagation = (fn: Function) => (e: any) => {
  e.stopPropagation();
  fn();
};

export function fuzzySearchRegex(query: string) {
  const escapedQuery = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special regex characters
  const fuzzyPattern = escapedQuery.split('').join('.*?');
  return new RegExp(fuzzyPattern, 'i'); // 'i' flag for case-insensitive matching
}

export const { theme } = resolveConfig(tailwindConfig);

export function getUniqueCommentAuthors(comments: IComment[]): IUser[] {
  const commentAuthors: IUser[] = [];

  function traverseComments(comments: IComment[]) {
    comments.forEach((comment) => {
      const author = comment.author;
      if (!commentAuthors.some((user) => user.id === author.id)) {
        commentAuthors.push(author);
      }
      if (comment?.responses?.length > 0) {
        traverseComments(comment.responses);
      }
    });
  }

  traverseComments(comments);
  return commentAuthors;
}

export function deepEqual(obj1: any, obj2: any): boolean {
  // Check if both objects are of the same type
  if (typeof obj1 !== typeof obj2) return false;

  // If both objects are arrays, handle array comparison
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false; // Different array lengths

    // Sort arrays to ensure consistent order for comparison
    const sortedArray1 = obj1.slice().sort();
    const sortedArray2 = obj2.slice().sort();

    // Compare sorted arrays element by element
    for (let i = 0; i < sortedArray1.length; i++) {
      if (!deepEqual(sortedArray1[i], sortedArray2[i])) return false; // Elements not equal
    }

    return true; // Arrays are deeply equal
  }

  // If both objects are not arrays, perform deep comparison for other types
  if (
    typeof obj1 === 'object' &&
    obj1 !== null &&
    typeof obj2 === 'object' &&
    obj2 !== null
  ) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false; // Different number of keys

    // Sort keys to ensure consistent order for comparison
    keys1.sort();
    keys2.sort();

    // Compare sorted keys and their corresponding values recursively
    for (let i = 0; i < keys1.length; i++) {
      const key = keys1[i];
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false; // Missing key in obj2 or deep inequality
      }
    }

    return true; // Objects are deeply equal
  }

  // For primitive types and other non-object/non-array types, use strict equality
  return obj1 === obj2;
}

export function deleteTypeNameKey(obj: any) {
  const key = '__typename';
  if (Object.keys(obj).includes(key)) {
    const clone = { ...obj };
    delete clone[key];
    return clone;
  }
  return obj;
}

export function getColorTag(
  str?: string
): 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' {
  if (!str) {
    return 'A';
  }
  // Hash the string
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash; // Convert to 32bit integer
  }

  // Map the hash to a letter between A and H
  const letters: ('A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H')[] = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
  ];
  return letters[Math.abs(hash) % letters.length];
}

export function getInitials(name: string): string {
  if (!name) return '';

  const words = name.split(/[^a-zA-Z]+/);

  const filteredWords = words.filter((word) => word.length > 0);

  if (filteredWords.length === 1) {
    const word = filteredWords[0];
    return word.slice(0, 2).toUpperCase();
  }

  const initials = filteredWords.map((word) => word[0]).join('');

  return initials.toUpperCase();
}

export function formatDate(dateString: string): string {
  const date = parseISO(dateString);
  const now = new Date();
  const differenceInSeconds = (now.getTime() - date.getTime()) / 1000;

  if (differenceInSeconds < 60) {
    return 'Just now';
  }

  if (differenceInSeconds < 24 * 60 * 60) {
    return formatDistanceToNow(date, { addSuffix: true });
  }

  if (differenceInSeconds < 2 * 24 * 60 * 60) {
    return 'Yesterday';
  }

  return format(date, 'MM/dd/yyyy');
}

export function getAvatarColorClass(initial: string): string {
  const uppercaseInitial = initial.toUpperCase();
  const letterCode = uppercaseInitial.charCodeAt(0);

  if (letterCode >= 65 && letterCode <= 90) {
    const index = letterCode - 65;
    return avatarColorClasses[index % avatarColorClasses.length];
  } else {
    return 'avatar-whisper';
  }
}

export const getProjectStatusColor: {
  [key in ProjectStatus]:
    | 'default'
    | 'info'
    | 'success'
    | 'negative'
    | 'warning'
    | 'disabled';
} = {
  [ProjectStatus.PLANNING]: 'info',
  [ProjectStatus.ONGOING]: 'warning',
  [ProjectStatus.COMPLETED]: 'success',
  [ProjectStatus.CANCELLED]: 'negative',
};

export const getTaskStatusColor: {
  [key in TaskStatus]:
    | 'default'
    | 'info'
    | 'success'
    | 'negative'
    | 'warning'
    | 'disabled';
} = {
  [TaskStatus.NOT_STARTED]: 'default',
  [TaskStatus.IN_PROGRESS]: 'warning',
  [TaskStatus.COMPLETED]: 'success',
  // [TaskStatus.CLOSED]: 'disabled',
  // [TaskStatus.OPEN]: 'default',
  // [TaskStatus.REVIEW]: 'info',
};
