import { useLocalStorage } from '@uidotdev/usehooks';
import { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMe } from 'src/api/auth/auth.service';
import { Eye, EyeSlash } from 'src/assets/icons';
import Button from 'src/components/ui/Button/Button';
import Input from 'src/components/ui/inputs/Default';
import SelectInput from 'src/components/ui/inputs/Select';
import Validator from 'validatorjs';
import { usePositionEnum } from '../api/usePositionEnum';
import { useUpsertOneUserDetail } from '../api/user/useUpsertOneUserDetail';
import { transformToTitleCase } from '../lib/helpers';

type FormData = {
  fullName: string;
  password: string;
  position: string;
};

function ProfileSetupPage() {
  const navigate = useNavigate();
  const [userId] = useLocalStorage('userId', undefined);

  const [passwordType, setPasswordType] = useState(true);
  const [selectModal, setSelectModal] = useState(false);
  const [role, setRole] = useState('');
  const positionEnum = usePositionEnum();
  const [formData, setFormData] = useState<FormData>({
    fullName: '',
    password: '',
    position: '',
  });
  const [formErrors, setFormErrors] =
    useState<Validator.ValidationErrors | null>(null);
  const [upsertOneUserDetail, { data, loading, error }] =
    useUpsertOneUserDetail();
  const errorMessage = error && `Submission error! ${error.message}`;
  const disabled = positionEnum.loading || loading;
  const me = useMe();

  const positionEnumValues = positionEnum.data?.__type.enumValues ?? [];

  useEffect(() => {
    if (!me.loading && me.data) {
      setFormData({
        ...formData,
        fullName: me.data.me.detail?.fullName,
        position: me.data.me.detail?.position,
      });
    }
  }, [me]);

  const handleInputChange = ({ field, e }: { field: string; e: any }) => {
    const value = typeof e === 'string' ? e : e.target.value;
    setFormData({ ...formData, [field]: value });
    setFormErrors(null);
  };

  const handleContinue = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validation = new Validator(formData, {
      fullName: 'required',
      password: 'required',
      position: 'required',
    });

    if (validation.passes()) {
      setFormErrors(null);
      upsertOneUserDetail({
        variables: {
          create: formData,
          update: {
            fullName: { set: formData.fullName },
            password: { set: formData.password },
            position: { set: formData.position },
          },
          where: { id: userId },
        },
        onCompleted: () => navigate('/'),
      });
    } else {
      setFormErrors(validation.errors.all());
    }
  };

  return (
    <div className="flex flex-col gap-10 md:gap-14">
      <div className="w-full flex flex-col gap-6 items-start">
        <div className="flex flex-col gap-2 items-start">
          <h3 className="text-heading-3-small bold text-[var(--text-default)] md:text-heading-3">
            Set up your profile
          </h3>
          <p className="text-body-small regular text-[var(--text-neutral)]">
            We’re very glad to have you onboard, kindly provide the following
            information to complete your account creation.
          </p>
        </div>
      </div>
      <form onSubmit={handleContinue} className="w-full flex flex-col gap-6">
        <Input
          autoFocus
          type="text"
          value={formData.fullName}
          feedback={''}
          feedbackType={'success'}
          label="Full name"
          placeholder="John Doe"
          onChange={(e) => {
            handleInputChange({ field: 'fullName', e });
          }}
        />
        <div className="flex flex-col gap-2">
          <label className="text-body-small regular text-[var(--text-default)]">
            What’s your role?
          </label>
          <SelectInput
            showDropGroup={selectModal}
            value={transformToTitleCase(formData.position)}
            style={{ maxHeight: '210px' }}
            data={positionEnumValues}
            placeholder="What’s your role?"
            onClick={() => setSelectModal(!selectModal)}
            onItemClick={(value) => {
              handleInputChange({ field: 'position', e: value.name });
              setSelectModal(false);
            }}
            clickOutside={() => setSelectModal(false)}
          />
        </div>
        <div className="relative">
          <Input
            type={passwordType ? 'password' : 'text'}
            password
            value={formData.password}
            feedback={''}
            feedbackType={false ? 'error' : 'success'}
            label="Create password"
            placeholder="Enter your password"
            onChange={(e) => {
              handleInputChange({ field: 'password', e });
            }}
            className="!pr-12"
          />
          <div
            className="absolute top-[41px] right-4"
            onClick={() => setPasswordType(!passwordType)}
          >
            {passwordType ? (
              <Eye className="w-4 h-4" />
            ) : (
              <EyeSlash className="w-4 h-4" />
            )}
          </div>
        </div>
        <Button
          type="submit"
          btnType="primary"
          size="large"
          value="Continue"
          loading={loading}
          disabled={loading}
          className="w-full"
        />
      </form>
    </div>
    // <main className="flex flex-col items-center justify-center min-h-screen p-4">
    //   <form onSubmit={handleContinue} className="w-full max-w-md">
    //     <div className="space-y-12">
    //       <div className="space-y-2">
    //         <h1 className="text-4xl font-medium text-grey-700 font-graphik">
    //           Setup your profile
    //         </h1>
    //         <p className="text-sm text-grey-300 font-light">
    //           We’re very glad to have you onboard,
    //           kindly provide the following information to complete your account creation
    //         </p>
    //       </div>

    //       <div className="space-y-6">
    //         <div className="space-y-2">
    //           <label htmlFor="fullName">
    //             Full name
    //           </label>
    //           <input
    //             id="fullName"
    //             placeholder="John Doe"
    //             className="w-full"
    //             value={formData.fullName}
    //             onChange={handleInputChange('fullName')}
    //           />
    //           <ValidationErrorMessages name='fullName' errors={formErrors}/>
    //         </div>

    //         <div className="space-y-2">
    //           <label htmlFor="position">
    //             What’s your position?
    //           </label>
    //           <select
    //             id="position"
    //             className="w-full"
    //             value={formData.position}
    //             onChange={handleInputChange('position')}
    //           >
    //             <option value="">Select...</option>
    //             {positionEnumValues.map(({name}: IPosition) => (
    //               <option key={name} value={name}>
    //                 {transformToTitleCase(name)}
    //               </option>
    //             ))}
    //           </select>
    //           <ValidationErrorMessages name='position' errors={formErrors}/>
    //         </div>

    //         <div className="space-y-2">
    //           <label htmlFor="password">
    //             Create password
    //           </label>
    //           <PasswordInput
    //             id="password"
    //             value={formData.password}
    //             onChange={handleInputChange('password')}
    //             className="w-full"
    //           />
    //           <ValidationErrorMessages name='password' errors={formErrors}/>
    //         </div>

    //         {errorMessage && (
    //           <p className="text-xs text-red-500">
    //             {errorMessage}
    //           </p>
    //         )}

    //         <Button disabled={disabled} loading={disabled} type="submit" className="w-full" color="primary">
    //           Create account
    //         </Button>
    //       </div>
    //     </div>

    //   </form>
    // </main>
  );
}

export default ProfileSetupPage;
