import React from 'react';
import { ChatBubble } from 'src/assets/icons';
import HorizontalDivider from 'src/components/ui/HorizontalDivider';
import { IComment } from 'src/types/entities';

function PostComments({
  comments,
  renderComment,
}: {
  comments: IComment[];
  renderComment: (comment: IComment) => JSX.Element;
}) {
  if (!!comments.length) {
    return (
      <ul className="p-5 flex flex-col gap-5">
        {comments.map((comment, index) => (
          <React.Fragment key={comment.id}>
            <li className="flex flex-col gap-2.5">{renderComment(comment)}</li>
            {index < comments.length - 1 && <HorizontalDivider />}
          </React.Fragment>
        ))}
      </ul>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center gap-1 mt-12">
      <ChatBubble className="h-8 w-8" />
      <div className="text-heading-6 medium text-[var(--text-default)]">
        No comments yet
      </div>
      <p className="text-body-small regular text-[var(--text-neutral)]">
        Add the first comment
      </p>
    </div>
  );
}

export default PostComments;
