import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { usePosts } from 'src/api/post/post.service';
import ToastMessage from 'src/components/ToastMessage';
import { IPost, IProjectLink, ProjectStatus } from 'src/lib/types';

interface IProjectDetails {
  name: string;
  description: string;
  collaboratorIDs: string[];
  topicIDs: string[];
  goalIDs: string[];
  status: ProjectStatus;
  isPublic: boolean;
  links: IProjectLink[];
}

interface PostsContextProps {
  projectId: string | undefined;
  workspaceSlug: string | undefined;
  posts: IPost[] | undefined;
  projectDetails: IProjectDetails;
  setProjectDetails: React.Dispatch<React.SetStateAction<IProjectDetails>>;
  isLoading: boolean;
  refetchPosts: () => void;
}

const PostsContext = createContext<PostsContextProps | undefined>(undefined);

export const PostsContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { projectId, workspaceSlug } = useParams<{
    projectId: string;
    workspaceSlug: string;
  }>();

  const [projectDetails, setProjectDetails] = React.useState<IProjectDetails>({
    name: '',
    description: '',
    collaboratorIDs: [],
    topicIDs: [],
    goalIDs: [],
    status: ProjectStatus.PLANNING,
    isPublic: true,
    links: [],
  });

  const queryVariables = useMemo(
    () => ({
      where: { projectId: { equals: projectId } },
      orderBy: { createdAt: 'desc' as const },
    }),
    [projectId]
  );

  const skipQuery = !projectId;

  const { loading, data, refetch } = usePosts({
    variables: queryVariables,
    skip: skipQuery,
    onError: (error: any) => {
      toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
    },
  });

  const refetchPosts = useCallback(() => {
    if (projectId && !skipQuery) {
      refetch(queryVariables);
    }
  }, [projectId, skipQuery, refetch, queryVariables]);

  useEffect(() => {
    if (!skipQuery) {
      refetchPosts();
    }
  }, [refetchPosts, skipQuery]);

  const contextValue = useMemo(
    () => ({
      posts: data?.posts,
      projectDetails,
      setProjectDetails,
      projectId,
      workspaceSlug,
      isLoading: loading,
      refetchPosts,
    }),
    [
      data?.posts,
      projectDetails,
      projectId,
      workspaceSlug,
      loading,
      refetchPosts,
    ]
  );

  return (
    <PostsContext.Provider value={contextValue}>
      {children}
    </PostsContext.Provider>
  );
};

export const usePostsContext = () => {
  const context = useContext(PostsContext);
  if (!context) {
    throw new Error(
      'usePostsContext must be used within a PostsContextProvider'
    );
  }
  return context;
};
