import { format } from 'date-fns';
import { IconCalendar, WorkStream } from 'src/assets/icons';
import Avatar from 'src/components/ui/Avatar/Avatar';
import ColorTag from 'src/components/ui/tags/ColorTags';
import StatusTag from 'src/components/ui/tags/StatusTags';
import { getColorTag, transformToTitleCase } from 'src/lib/helpers';
import { ITask } from 'src/types/entities';
import { tv } from 'tailwind-variants';
// import parse from 'html-react-parser';

interface TaskCardProps {
  task?: ITask;
  cardType: 'listView' | 'kanbanView';
  onClick?: () => void;
}

const TaskCard = (props: TaskCardProps) => {
  const { task, cardType, onClick } = props;
  return (
    <div className={taskCardStyles({ type: `${cardType}` })} onClick={onClick}>
      <div className={taskCardLeftSlotStyles({ type: `${cardType}` })}>
        <ColorTag
          value={task?.workStream[0]?.name || 'Design'}
          size="medium"
          type="filled"
          color={getColorTag(task?.workStream[0]?.name || 'design')}
          leftIcon={<WorkStream className="w-3 h-3" />}
        />
        <h3 className="text-body-small medium text-[var(--text-default)]">
          {task?.title ? transformToTitleCase(task.title) : ''}
        </h3>
      </div>

      <div className={taskCardRightSlotStyles({ type: `${cardType}` })}>
        <StatusTag
          value={
            task?.dueDate
              ? format(new Date(task.dueDate), 'MMM dd')
              : 'Set due date'
          }
          size="medium"
          type="filled"
          color="default"
          leftIcon={<IconCalendar className="w-3 h-3" />}
        />
        <Avatar
          name={task?.assignee?.detail.fullName || 'User'}
          src={task?.assignee?.detail.avatar?.url}
          alt={`${task?.assignee?.detail.fullName} profile picture`}
          size={20}
        />
      </div>
    </div>
  );
};

const taskCardStyles = tv({
  base: 'w-full flex  justify-between bg-[var(--backgrounds-on-canvas)] border border-[var(--border-default)] p-3 rounded-[8px] hover:bg-[var(--backgrounds-default)] cursor-pointer',
  variants: {
    type: {
      listView: 'h-[45px] items-center',
      kanbanView: 'h-[110px] items-start flex-col',
    },
  },
  defaultVariants: {
    type: 'listView',
  },
});

const taskCardLeftSlotStyles = tv({
  base: 'flex',
  variants: {
    type: {
      listView: 'gap-x-2 justify-start w-max items-center',
      kanbanView: 'gap-y-2 flex-col items-start w-full',
    },
  },
  defaultVariants: {
    type: 'listView',
  },
});

const taskCardRightSlotStyles = tv({
  base: 'flex items-center gap-x-2',
  variants: {
    type: {
      listView: 'justify-start',
      kanbanView: 'justify-between w-full',
    },
  },
  defaultVariants: {
    type: 'listView',
  },
});

export default TaskCard;
