import { useLocalStorage } from '@uidotdev/usehooks';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import TextareaAutosize from 'react-textarea-autosize';
import { useUpdateOneTask } from 'src/api/task/task.service';
import { ChevronDown } from 'src/assets/icons';
import ToastMessage from 'src/components/ToastMessage';
import Avatar from 'src/components/ui/Avatar/Avatar';
import Button from 'src/components/ui/Button/Button';
import IconButton from 'src/components/ui/Button/IconButton';
import Timestamp from 'src/components/ui/TimeStamp';
import { IComment } from 'src/types/entities';
import DeleteTaskCommentModal from './DeleteTaskCommentModal';
import TaskCommentMenu from './TaskCommentMenu';

function TaskCommentBody({ comment }: { comment: IComment }) {
  const [userId] = useLocalStorage('userId', undefined);
  const isCommentOwner = userId === comment.author.id;
  const [updateOneTask, { loading: updateCommentLoading }] = useUpdateOneTask();
  const [editComment, setEditComment] = useState<string | undefined>(undefined);
  const [deleteComment, setDeleteComment] = useState<IComment | null>(null);

  const handleCopy = () => {
    const origin = window.location.origin;
    const post = comment.post;
    const project = post.project;
    //@ts-ignore
    const workspace = project.workspace;
    //@ts-ignore
    const link = `${origin}/workspace/${workspace.slug}/project/${project.id}?post=${post.id}&comment=${comment.id}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title={'Link to comment is copied to clipboard.'}
          />
        ));
      })
      .catch((err) => {
        toast((t) => (
          <ToastMessage id={t.id} title={'Error copying text to clipboard.'} />
        ));
      });
  };

  const handleSave = () => {
    updateOneTask({
      variables: {
        data: {
          comments: {
            update: [
              {
                data: {
                  comment: { set: editComment },
                },
                where: { id: comment.id },
              },
            ],
          },
        },
        where: { id: comment.task.id },
      },
      onCompleted: () => {
        setEditComment(undefined);
      },
    });
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      const lineHeight = parseFloat(
        getComputedStyle(contentRef.current).lineHeight
      );
      const maxHeight = lineHeight * 6;

      if (contentRef.current.scrollHeight > maxHeight) {
        setIsTruncated(false);
      }
    }
  }, [comment]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    setIsHovered(!isHovered);
  };

  return (
    <div className="duration-1000 flex flex-col gap-4">
      <div className="flex items-center space-x-6">
        <div className="flex-1 flex space-x-2 items-center">
          <Avatar
            alt={comment.author.detail.fullName}
            src={comment.author.detail.avatar?.url}
            size={28}
            name={comment.author.detail.fullName}
          />
          <div className="">
            <div className="text-body-small text-[var(--text-default)] font-medium">
              {comment.author.detail.fullName}
            </div>
            <div className="text-body-micro text-[var(--text-neutral)] regular">
              <Timestamp createdAt={comment.createdAt} />
            </div>
          </div>
        </div>

        <TaskCommentMenu
          canEdit={isCommentOwner}
          canDelete={isCommentOwner}
          canArchive={isCommentOwner}
          handleCopy={handleCopy}
          handleEdit={() => setEditComment(comment.comment)}
          handleDelete={() => setDeleteComment(comment)}
          type={'comment'}
        />
      </div>

      {Boolean(editComment) || (
        <div className="w-full relative">
          <p
            ref={contentRef}
            className={`text-body-small regular text-[var(--text-default)] ${
              isExpanded ? '' : 'article truncate-lines-'
            }`}
          >
            {comment.comment}
          </p>
          {isTruncated && (
            <div
              className="absolute bottom-0 h-[72px] w-full flex flex-col items-center justify-end"
              style={{
                background:
                  !isExpanded || isHovered ? 'var(--truncate-bg)' : 'none',
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <AnimatePresence>
                {isExpanded && isHovered && (
                  <motion.div
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 10, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <IconButton
                      icon={<ChevronDown className="rotate-180" />}
                      size={'standard'}
                      type={'neutral'}
                      rounded="full"
                      onClick={toggleExpand}
                    />
                  </motion.div>
                )}

                {!isExpanded && (
                  <IconButton
                    icon={<ChevronDown />}
                    size={'standard'}
                    type={'neutral'}
                    onClick={toggleExpand}
                  />
                )}
              </AnimatePresence>
            </div>
          )}
        </div>
      )}

      {Boolean(editComment) && (
        <div className="space-y-4">
          <TextareaAutosize
            autoFocus
            value={editComment}
            onChange={(e) => setEditComment(e.target.value)}
            placeholder="Edit this comment"
            disabled={updateCommentLoading}
            className="resize-none w-full text-sm input"
          />

          <div className="flex items-center justify-end space-x-3">
            <Button
              onClick={() => setEditComment(undefined)}
              type="button"
              size="small"
              className={'w-fit'}
              value={'Cancel'}
              btnType={'neutral'}
            />
            <Button
              onClick={handleSave}
              type="button"
              size="small"
              className={'w-fit'}
              loading={updateCommentLoading}
              disabled={updateCommentLoading || editComment === comment.comment}
              value={'Save'}
              btnType={'primary'}
            />
          </div>
        </div>
      )}

      <DeleteTaskCommentModal
        comment={deleteComment}
        show={Boolean(deleteComment)}
        handleClose={() => setDeleteComment(null)}
      />
    </div>
  );
}

export default TaskCommentBody;
