import { Menu, Transition } from '@headlessui/react';
import { useLocalStorage } from '@uidotdev/usehooks';
import { ChangeEvent, FormEvent, Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useInviteTeamToOneWorkspace } from 'src/api/workspace/useInviteTeamToOneWorkspace';
import { useWorkspace } from 'src/api/workspace/useWorkspace';
import {
  useDeactivateOneWorkspaceMember,
  useDeleteOneWorkspaceInvitation,
  useReactivateOneWorkspaceMember,
  useUpdateOneWorkspace,
  useUpdateOneWorkspaceInvitation,
  useUpdateOneWorkspaceMemberRole,
} from 'src/api/workspace/workspace.service';
import {
  ChevronDown,
  Issues,
  Plus,
  ThreeDots,
  Trash,
  Triangle,
} from 'src/assets/icons';
import ToastMessage from 'src/components/ToastMessage';
import NameAvatar from 'src/components/ui/Avatar/NameAvatar';
import Button from 'src/components/ui/Button/Button';
import IconButton from 'src/components/ui/Button/IconButton';
import { DropItem } from 'src/components/ui/DropGroup';
import HeaderText from 'src/components/ui/HeaderTexts';
import HorizontalDivider from 'src/components/ui/HorizontalDivider';
import Input from 'src/components/ui/inputs/Default';
import PageLoader from 'src/components/ui/Loader/PageLoader';
import { PopOverItem } from 'src/components/ui/tables/PopOver';
import Tabs, { Tab } from 'src/components/ui/tables/Tab';
import TableLayout from 'src/components/ui/tables/TableLayout';
import SwitchToggle from 'src/components/ui/ToggleSwitch';
import { useWorkspaceContext } from 'src/context/workspaceContext';
import Validator from 'validatorjs';
import ValidationErrorMessages from '../../../components/ValidationErrorMessages';
import { transformToTitleCase } from '../../../lib/helpers';
import { WORKSPACE } from '../../../lib/queries';
import {
  IInvitation,
  IWorkspace,
  IWorkspaceMember,
  UserWorkspaceRole,
} from '../../../lib/types';

enum TabType {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  INVITED = 'INVITED',
  DEACTIVATED = 'DEACTIVATED',
}

type EmailRole = {
  email: string;
  role: UserWorkspaceRole;
};

type FormData = {
  emailRoles: Array<EmailRole>;
};

const workspaceRoleOptions = [
  {
    role: UserWorkspaceRole.ADMIN,
    snippet: 'Admin can manage workspace, members, create and manage projects.',
  },
  {
    role: UserWorkspaceRole.MEMBER,
    snippet:
      'Member can participate in projects and access workspace resources.',
  },
];

const tabs: Tab[] = Object.keys(TabType).map((key) => ({
  id: key,
  value: TabType[key as keyof typeof TabType].toLowerCase(),
}));

const transformMember = (member: IWorkspaceMember) => ({
  id: member?.id,
  name: member?.user.detail.fullName,
  position: member?.user.detail.position.toLowerCase().split('_').join(' '),
  workspaceRole: member?.role,
  userRole: member?.user.role,
  status: member?.user.status,
  isOwner: member?.isOwner,
  memberId: member?.user.id,
});
const transformInvitation = (invite: IInvitation) => ({
  id: invite.id,
  inviteeRole: invite.role,
  inviteeEmail: invite.inviteeEmail,
});

const Members = () => {
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();
  const [userId] = useLocalStorage('userId', undefined);
  const [currentTab, setCurrentTab] = useState<string>(tabs[0].id);
  const {
    workspaceMembers,
    workspaceDeactivatedMembers,
    workspaceInvitations,
  } = useWorkspaceContext();

  const queryVariables = {
    where: { slug: workspaceSlug },
  };
  const {
    loading: workspaceLoading,
    data: workspaceData,
    error: workspaceError,
  } = useWorkspace({
    variables: queryVariables,
  });

  const [
    inviteTeamToOneWorkspace,
    {
      loading: inviteTeamToOneWorkspaceLoading,
      error: inviteTeamToOneWorkspaceError,
    },
  ] = useInviteTeamToOneWorkspace();

  const [updateOneWorkspaceMemberRole] = useUpdateOneWorkspaceMemberRole();
  const [updateOneWorkspaceInvitation] = useUpdateOneWorkspaceInvitation();
  const [deactivateOneWorkspaceMember] = useDeactivateOneWorkspaceMember();
  const [reactivateOneWorkspaceMember] = useReactivateOneWorkspaceMember();
  const [deleteOneWorkspaceInvitation] = useDeleteOneWorkspaceInvitation();
  const [updateOneWorkspace] = useUpdateOneWorkspace();

  let workspaceUserRole: UserWorkspaceRole | null = null;

  const initialEmailRoles = [
    {
      email: '',
      role:
        workspaceUserRole !== UserWorkspaceRole.ADMIN
          ? UserWorkspaceRole.MEMBER
          : UserWorkspaceRole.ADMIN,
    },
  ];

  const [formData, setFormData] = useState<FormData>({
    emailRoles: initialEmailRoles,
  });
  const [formErrors, setFormErrors] =
    useState<Validator.ValidationErrors | null>(null);

  const workspace: IWorkspace | undefined = workspaceData?.workspace;
  workspaceUserRole = workspaceMembers()?.find(
    (member: IWorkspaceMember) => member.user.id === userId
  )?.role as UserWorkspaceRole;

  const isWorkspaceOwner =
    workspaceMembers()?.find(
      (member: IWorkspaceMember) => member.user.id === userId
    )?.isOwner ?? false;

  const activeMembers = workspaceMembers()?.map(
    transformMember
  ) as unknown as IWorkspaceMember[];

  const deactivatedMembers = workspaceDeactivatedMembers()?.map(
    transformMember
  ) as unknown as IWorkspaceMember[];

  const invitedMembers = workspaceInvitations()?.map(transformInvitation);
  const roles: string[] = Object.keys(UserWorkspaceRole);

  const membersCount = {
    [TabType.ALL]:
      activeMembers?.length +
      deactivatedMembers?.length +
      (invitedMembers?.length ?? 0),
    [TabType.ACTIVE]: activeMembers?.length,
    [TabType.DEACTIVATED]: deactivatedMembers?.length,
    [TabType.INVITED]: invitedMembers?.length,
  };
  const currentMembersCount =
    membersCount[currentTab as keyof typeof TabType] ?? 0;
  const showActiveMembers =
    TabType.ALL === currentTab || TabType.ACTIVE === currentTab;
  const showDeactivatedMembers =
    TabType.ALL === currentTab || TabType.DEACTIVATED === currentTab;
  const showInvitedMembers =
    TabType.ALL === currentTab || TabType.INVITED === currentTab;

  const secretLink = workspace?.inviteToken
    ? `${window.location.origin}/accept-invite-link?token=${workspace.inviteToken}`
    : '';

  const inviteTeamToOneWorkspaceErrorMessage =
    inviteTeamToOneWorkspaceError && inviteTeamToOneWorkspaceError.message;

  const handleEmailRolesInputChange =
    (field: string, index: number) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
      const value = typeof e === 'string' ? e : e.target.value;
      setFormData((prevData) => ({
        emailRoles: [
          ...prevData.emailRoles.slice(0, index),
          { ...prevData.emailRoles[index], [field]: value },
          ...prevData.emailRoles.slice(index + 1),
        ],
      }));
      setFormErrors(null);
    };

  const handleAddAnotherEmailRole = () => {
    const lastEmailRole = formData.emailRoles[formData.emailRoles.length - 1];

    if (lastEmailRole && lastEmailRole.email) {
      setFormData({
        emailRoles: [
          ...formData.emailRoles,
          { email: '', role: UserWorkspaceRole.ADMIN },
        ],
      });
    }
  };

  const handleRemoveEmailRole = (index: number) => () => {
    if (formData.emailRoles.length === 1) return;
    setFormData({
      emailRoles: [
        ...formData.emailRoles.slice(0, index),
        ...formData.emailRoles.slice(index + 1),
      ],
    });
  };

  const handleInviteTeam = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validation = new Validator(
      formData,
      {
        'emailRoles.*.email': ['required', 'email'],
      },
      {
        required: 'The email field is required.',
        email: 'That email is invalid.',
      }
    );

    if (validation.passes()) {
      setFormErrors(null);

      await inviteTeamToOneWorkspace({
        variables: {
          data: {
            emailRoles: formData.emailRoles,
          },
          where: { slug: workspaceSlug },
        },
        onCompleted: () => {
          setFormData({
            emailRoles: initialEmailRoles,
          });
          toast((t) => (
            <ToastMessage
              id={t.id}
              title={'Workspace email invites sent successfully'}
            />
          ));
        },
        onError: (error: any) => {
          toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
        },
      });
    } else {
      setFormErrors(validation.errors.all());
    }
  };

  const handleClickMemberRole = async (
    memberId: string,
    role: UserWorkspaceRole
  ) => {
    await updateOneWorkspaceMemberRole({
      variables: {
        data: {
          memberId: memberId,
          role: role,
        },
        where: {
          slug: workspaceSlug,
        },
      },
      onCompleted: () => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title={'Workspace member role updated successfully'}
          />
        ));
      },
      onError: (error) => {
        toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
      },
    });
  };

  const handleClickInviteeRole = async (
    inviteId: string,
    role: UserWorkspaceRole
  ) => {
    if (workspaceUserRole !== UserWorkspaceRole.ADMIN) {
      toast((t) => (
        <ToastMessage
          id={t.id}
          title={'Only admin role is allowed for invited members'}
        />
      ));
      return;
    }

    await updateOneWorkspaceInvitation({
      variables: {
        data: {
          role: { set: role },
        },
        where: {
          id: inviteId,
        },
      },
      onCompleted: () => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title={'Workspace member role updated successfully'}
          />
        ));
      },
      onError: (error) => {
        toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
      },
    });
  };

  const handleClickDeactivateMember = async (memberId: string) => {
    if (workspaceUserRole !== UserWorkspaceRole.ADMIN) {
      toast((t) => (
        <ToastMessage
          id={t.id}
          title={'Only an admin is allowed to deactivate members'}
        />
      ));
      return;
    }
    await deactivateOneWorkspaceMember({
      variables: {
        data: {
          memberId: memberId,
        },
        where: {
          slug: workspaceSlug,
        },
      },
      onCompleted: () => {
        toast((t) => (
          <ToastMessage id={t.id} title={'Member deactivated successfully'} />
        ));
      },
      onError: (error) => {
        toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
      },
    });
  };

  const handleClickReactivateMember = async (memberId: string) => {
    await reactivateOneWorkspaceMember({
      variables: {
        data: {
          memberId: memberId,
        },
        where: {
          slug: workspaceSlug,
        },
      },
      onCompleted: () => {
        toast((t) => (
          <ToastMessage id={t.id} title={'Member reactivated successfully'} />
        ));
      },
      onError: (error) => {
        toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
      },
    });
  };

  const handleClickRemoveInvite = async (inviteId: string) => {
    await deleteOneWorkspaceInvitation({
      variables: {
        where: {
          id: inviteId,
        },
      },
      update: (cache, { data: { deleteOneWorkspaceInvitation } }) => {
        const { workspace }: any = cache.readQuery({
          query: WORKSPACE,
          variables: queryVariables,
        });
        cache.writeQuery({
          query: WORKSPACE,
          variables: queryVariables,
          data: {
            workspace: {
              ...workspace,
              invitations: workspace.invitations.filter(
                (invite: IInvitation) =>
                  invite.id !== deleteOneWorkspaceInvitation.id
              ),
            },
          },
        });
      },
      onCompleted: () => {
        toast((t) => (
          <ToastMessage id={t.id} title={'Invitation removed successfully'} />
        ));
      },
      onError: (error) => {
        toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
      },
    });
  };

  const handleToggleInviteToken = () => {
    updateOneWorkspace({
      variables: {
        data: {
          enableInviteToken: {
            set: !workspaceData?.workspace.enableInviteToken,
          },
        },
        where: {
          slug: workspaceSlug,
        },
      },
      optimisticResponse: {
        updateOneWorkspace: {
          ...workspaceData?.workspace,
          enableInviteToken: !workspaceData?.workspace.enableInviteToken,
        },
      },
      onCompleted: (data) => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title={`Invitation link is ${
              data.updateOneWorkspace.enableInviteToken ? 'enabled' : 'disabled'
            }`}
          />
        ));
      },
      onError: (error) => {
        toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
      },
    });
  };

  const handleCopyInviteLink = () => {
    navigator.clipboard
      .writeText(secretLink)
      .then(() => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title={'Link copied to clipboard'}
          />
        ));
      })
      .catch((err) => {
        toast((t) => <ToastMessage id={t.id} title={`${err.message}`} />);
      });
  };

  if (workspaceLoading)
    return (
      <PageLoader snippet="Loading your workspace's projects, and posts" />
    );
  if (workspaceError || !workspace) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-4rem)]">
        <p className="text-lg text-red-500">{workspaceError?.message}</p>
      </div>
    );
  }

  return (
    <Fragment>
      <div className="workspace-page flex flex-col gap-10">
        <div className="header_div">
          <HeaderText title="Members" />
          <HorizontalDivider />
        </div>

        {/* invite members by link */}
        <div className="flex flex-col items-start gap-6">
          <div className="flex items-center w-full justify-between gap-4">
            <HeaderText
              subTitle="Invite with link"
              snippet="Enable a secret link for workspace admins to invite new members."
            />
            <SwitchToggle
              id="toggle1"
              disabled={isWorkspaceOwner === null}
              isChecked={workspace?.enableInviteToken ?? false}
              onChange={handleToggleInviteToken}
              label="Toggle 1"
              className="toggle-container"
            />
          </div>
          {workspace?.enableInviteToken && (
            <div className="flex gap-3 w-full">
              <div className="truncate secret-link flex items-center px-4 rounded-lg bg-[var(--backgrounds-default)] w-full">
                <p className="truncate text-body-small regular text-[var(--text-on-bg)]">
                  {secretLink}
                </p>
              </div>
              <div>
                <Button
                  onClick={handleCopyInviteLink}
                  size="medium"
                  btnType="neutral"
                  icon="left"
                  value={'Copy link'}
                >
                  <div>
                    <Issues />
                  </div>
                </Button>
              </div>
            </div>
          )}
        </div>
        <HorizontalDivider />

        {/* Invite with email */}
        <form
          onSubmit={handleInviteTeam}
          className="flex flex-col items-start gap-6"
        >
          <HeaderText
            subTitle="Invite with email"
            snippet="Invite new members to your workspace."
          />
          <div className="w-full flex flex-col gap-8">
            {formData.emailRoles.map((item, index) => (
              <div>
                <div key={index} className="w-full flex items-end gap-3">
                  <Input
                    type="email"
                    value={formData.emailRoles[index].email}
                    label={index === 0 ? 'Email' : ''}
                    placeholder="Enter email"
                    onChange={handleEmailRolesInputChange('email', index)}
                    feedback=""
                  />
                  <div className="cta flex gap-3 items-center">
                    <Menu as="div" className="relative inline-block ml-auto">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button
                              disabled={
                                workspaceUserRole !== UserWorkspaceRole.ADMIN
                              }
                              className={'disabled:cursor-not-allowed'}
                            >
                              <div
                                className={`${
                                  open
                                    ? 'bg-[var(--button-neutral-hover-clicked)]'
                                    : ''
                                } flex items-center px-4 h-10 gap-2 border border-[var(--border-input-default)] rounded-lg cursor-pointer`}
                              >
                                <p className="text-body-small regular text-[var(--text-default)]">
                                  {transformToTitleCase(
                                    formData.emailRoles[index].role
                                  )}
                                </p>
                                <div>
                                  <Triangle />
                                </div>
                              </div>
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="drop-group absolute z-10 right-0 mt-2 w-48 origin-top-right">
                              <div className="px-1 py-1 ">
                                {roles.map((role) => (
                                  <Menu.Item key={role}>
                                    {({ active }) => (
                                      <button type="button">
                                        <DropItem
                                          item={{
                                            name: transformToTitleCase(role),
                                          }}
                                          onItemClick={() =>
                                            handleEmailRolesInputChange(
                                              'role',
                                              index
                                            )(role)
                                          }
                                        />
                                      </button>
                                    )}
                                  </Menu.Item>
                                ))}
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>

                    <IconButton
                      icon={<Trash />}
                      size="standard"
                      type="neutral"
                      onClick={handleRemoveEmailRole(index)}
                      disabled={formData.emailRoles.length === 1}
                    />
                  </div>
                </div>
                <ValidationErrorMessages
                  name={`emailRoles.${index}.email`}
                  errors={formErrors}
                />
              </div>
            ))}
            <div className="flex items-center justify-between">
              <Button
                onClick={handleAddAnotherEmailRole}
                size="medium"
                btnType="neutral"
                icon="left"
                value={'Add another'}
                className="w-auto"
                type="button"
                disabled={
                  inviteTeamToOneWorkspaceLoading ||
                  formData.emailRoles.some((item) => !item.email)
                }
              >
                <Plus />
              </Button>
              <Button
                type="submit"
                btnType="primary"
                size="medium"
                value="Send invite"
                loading={inviteTeamToOneWorkspaceLoading}
                disabled={inviteTeamToOneWorkspaceLoading}
                className="w-auto"
              />
            </div>
          </div>
          {inviteTeamToOneWorkspaceErrorMessage && (
            <p className="text-xs text-red-500">
              {inviteTeamToOneWorkspaceErrorMessage}
            </p>
          )}
        </form>
        <HorizontalDivider />

        {/* Manage members */}
        <div className="flex flex-col items-start gap-8 w-full">
          <HeaderText
            subTitle="Manage members"
            snippet="Manage the members in your workspace."
          />
          <Tabs
            tabs={tabs}
            onTabChange={setCurrentTab}
            initialSelectedTab={currentTab}
          />
          <p className="text-body-small medium text-[var(--text-default)]">
            {currentMembersCount > 0 ? currentMembersCount : 'No'}{' '}
            <span className="capitalize">
              {currentTab !== 'ALL' && currentTab.toLowerCase()}
            </span>{' '}
            {currentMembersCount > 1 ? 'Members' : 'Member'}
          </p>

          <div className="flex flex-col items-start w-full">
            {/* Active Members */}
            {showActiveMembers &&
              activeMembers?.map(
                ({
                  id,
                  memberId,
                  name,
                  userRole,
                  workspaceRole,
                  isOwner,
                  position,
                }: any) => (
                  <TableLayout
                    key={`${memberId}-${name}`}
                    Col1={
                      <NameAvatar
                        loading={false}
                        name={name}
                        position={position}
                        role={isOwner && 'OWNER'}
                      />
                    }
                    Col2={
                      <Menu as="div" className="relative inline-block mr-auto">
                        {({ open }) => (
                          <>
                            <div>
                              <Menu.Button>
                                <Button
                                  btnType="ghost"
                                  size="small"
                                  value={transformToTitleCase(workspaceRole)}
                                  disabled={
                                    workspaceUserRole !==
                                    UserWorkspaceRole.ADMIN
                                  }
                                  className={`w-auto ${
                                    open
                                      ? 'bg-[var(--button-neutral-hover-clicked)]'
                                      : ''
                                  }`}
                                >
                                  <ChevronDown />
                                </Button>
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="drop-group absolute z-10 right-0 mt-2 w-[320px] origin-top-right">
                                <div className="px-1 py-1 ">
                                  {workspaceRoleOptions.map(
                                    ({ role, snippet }) => (
                                      <Menu.Item>
                                        {({ active }) => (
                                          <PopOverItem
                                            key={role}
                                            name={transformToTitleCase(role)}
                                            snippet={snippet}
                                            active={workspaceRole === role}
                                            onClick={() =>
                                              handleClickMemberRole(
                                                memberId,
                                                role
                                              )
                                            }
                                          />
                                        )}
                                      </Menu.Item>
                                    )
                                  )}
                                </div>
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                    }
                    menu={
                      <Menu as="div" className="relative inline-block ml-auto">
                        {({ open }) => (
                          <>
                            <div>
                              <Menu.Button>
                                <IconButton
                                  size="micro"
                                  type="ghost"
                                  state={open ? 'open' : undefined}
                                  icon={<ThreeDots />}
                                />
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="drop-group absolute z-10 right-0 mt-2 w-48 origin-top-right">
                                <div className="px-1 py-1 ">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <DropItem
                                        disabled={true}
                                        item={{ name: 'Change email' }}
                                      />
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <DropItem
                                        disabled={
                                          workspaceUserRole !==
                                          UserWorkspaceRole.ADMIN
                                        }
                                        item={{ name: 'Deactivate member' }}
                                        onItemClick={() =>
                                          handleClickDeactivateMember(memberId)
                                        }
                                      />
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                    }
                  />
                )
              )}
            {/* Invited Members */}
            {showInvitedMembers &&
              invitedMembers?.map(({ id, inviteeEmail, inviteeRole }) => (
                <TableLayout
                  key={inviteeEmail}
                  Col1={
                    <NameAvatar
                      loading={false}
                      name={inviteeEmail}
                      role="Invited"
                    />
                  }
                  Col2={
                    <Menu as="div" className="relative inline-block mr-auto">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button>
                              <Button
                                btnType="ghost"
                                size="small"
                                value={transformToTitleCase(
                                  inviteeRole as string
                                )}
                                disabled={
                                  workspaceUserRole !== UserWorkspaceRole.ADMIN
                                }
                                className={`w-auto ${
                                  open
                                    ? 'bg-[var(--button-neutral-hover-clicked)]'
                                    : ''
                                }`}
                              >
                                <ChevronDown />
                              </Button>
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="drop-group absolute z-10 right-0 mt-2 w-[320px] origin-top-right">
                              <div className="px-1 py-1 ">
                                {workspaceRoleOptions.map(
                                  ({ role, snippet }) => (
                                    <Menu.Item>
                                      {({ active }) => (
                                        <PopOverItem
                                          key={role}
                                          name={transformToTitleCase(role)}
                                          snippet={snippet}
                                          active={inviteeRole === role}
                                          onClick={() =>
                                            handleClickInviteeRole(id, role)
                                          }
                                        />
                                      )}
                                    </Menu.Item>
                                  )
                                )}
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  }
                  menu={
                    <Menu as="div" className="relative inline-block ml-auto">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button>
                              <IconButton
                                size="micro"
                                type="ghost"
                                state={open ? 'open' : undefined}
                                icon={<ThreeDots />}
                              />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="drop-group absolute z-10 right-0 mt-2 w-48 origin-top-right">
                              <div className="px-1 py-1 ">
                                <Menu.Item>
                                  {({ active }) => (
                                    <DropItem
                                      disabled={true}
                                      item={{ name: 'Change email' }}
                                    />
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <DropItem
                                      disabled={
                                        workspaceUserRole !==
                                        UserWorkspaceRole.ADMIN
                                      }
                                      item={{ name: 'Remove member' }}
                                      onItemClick={() =>
                                        handleClickRemoveInvite(id)
                                      }
                                    />
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  }
                />
              ))}
            {/* Deactivated Members */}
            {showDeactivatedMembers &&
              deactivatedMembers.map(
                ({
                  id,
                  memberId,
                  name,
                  userRole,
                  workspaceRole,
                  status,
                  position,
                }: any) => (
                  <TableLayout
                    key={name}
                    containerClassName={'opacity-50'}
                    Col1={
                      <NameAvatar
                        loading={false}
                        name={name}
                        position={position}
                        role={'Deactivated'}
                      />
                    }
                    Col2={
                      <Menu as="div" className="relative inline-block mr-auto">
                        {({ open }) => (
                          <>
                            <div>
                              <Menu.Button>
                                <Button
                                  btnType="ghost"
                                  size="small"
                                  value={transformToTitleCase(workspaceRole)}
                                  className={`w-auto ${
                                    open
                                      ? 'bg-[var(--button-neutral-hover-clicked)]'
                                      : ''
                                  }`}
                                >
                                  <ChevronDown />
                                </Button>
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="drop-group absolute z-10 right-0 mt-2 w-[320px] origin-top-right">
                                <div className="px-1 py-1 ">
                                  {workspaceRoleOptions.map(
                                    ({ role, snippet }) => (
                                      <Menu.Item>
                                        {({ active }) => (
                                          <PopOverItem
                                            key={role}
                                            name={transformToTitleCase(role)}
                                            snippet={snippet}
                                            active={workspaceRole === role}
                                            onClick={() =>
                                              handleClickMemberRole(
                                                memberId,
                                                role
                                              )
                                            }
                                          />
                                        )}
                                      </Menu.Item>
                                    )
                                  )}
                                </div>
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                    }
                    menu={
                      <Menu as="div" className="relative inline-block ml-auto">
                        {({ open }) => (
                          <>
                            <div>
                              <Menu.Button>
                                <IconButton
                                  size="micro"
                                  type="ghost"
                                  state={open ? 'open' : undefined}
                                  icon={<ThreeDots />}
                                />
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="drop-group absolute z-10 right-0 mt-2 w-48 origin-top-right">
                                <div className="px-1 py-1 ">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button>
                                        <DropItem
                                          item={{ name: 'Reactivate member' }}
                                          onItemClick={() =>
                                            handleClickReactivateMember(
                                              memberId
                                            )
                                          }
                                        />
                                      </button>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                    }
                  />
                )
              )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Members;
