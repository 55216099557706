import { useLocalStorage } from '@uidotdev/usehooks';
import {
  ClockOutline,
  Files,
  IconInformation,
  Lock,
  Status,
  UserCircle,
  Users,
} from 'src/assets/icons';
import ProjectCollaboratorsSelect from 'src/components/projects/ProjectCollaboratorsSelect';
import ProjectLinksSelect from 'src/components/projects/ProjectLinksSelect';
import ProjectPublicAccessSelect from 'src/components/projects/ProjectPublicAccessSelect';
import ProjectStatusSelect from 'src/components/projects/ProjectStatusSelect';
import { IProjectLink, ProjectStatus } from 'src/lib/types';

import AssignItem from '../ui/AssignItem';
import Timestamp from '../ui/TimeStamp';
import ProjectLeadSelect from './ProjectLeadSelect';

interface EditProjectDetailsProps {
  projectId?: string;
  project?: any;
  workspaceSlug: string;
  value: {
    owner: string;
    collaboratorIDs: string[];
    topicIDs: string[];
    // goalIDs: string[];
    status: ProjectStatus;
    isPublic: boolean;
    links: IProjectLink[];
  };
  onChange: (field: string, value: any) => void;
  readOnly?: boolean;
  user?: {
    name: string;
    src: string;
    alt: string;
    position: string;
  };
}

function ProjectMetaDetails({
  projectId,
  project,
  onChange,
  value,
  workspaceSlug,
  readOnly = true,
  user,
}: EditProjectDetailsProps) {
  const [userId] = useLocalStorage('userId', undefined);

  return (
    <table className="-my-3 w-full font-medium text-sm">
      <tbody>
        <tr>
          <td className="w-[120px] md:w-[133px] py-3 flex items-start">
            <AssignItem
              icon={<IconInformation className="!w-3.5 !h-3.5" />}
              text="About"
            />
          </td>
          <td className="py-3">
            <p
              className="ck ck-content text-[var(--text-neutral)] text-body-small regular px-[6px]"
              dangerouslySetInnerHTML={{ __html: project.description }}
            />
          </td>
        </tr>
        {/* Project lead */}
        <tr>
          <td className="w-[120px] md:w-[133px] py-3">
            <AssignItem
              icon={<UserCircle className="!w-3.5 !h-3.5" />}
              text="Project Lead"
            />
          </td>
          <td className="py-3">
            <ProjectLeadSelect
              userId={userId}
              projectId={projectId}
              workspaceSlug={workspaceSlug}
              value={value.owner}
              onChange={(value) => onChange('owner', value)}
              readOnly={readOnly}
            />
          </td>
        </tr>

        <tr>
          <td className="w-[120px] md:w-[133px] py-3">
            <AssignItem
              icon={<ClockOutline className="!w-3.5 !h-3.5" />}
              text="Created"
            />
          </td>
          <td className="py-3">
            <Timestamp
              createdAt={project.createdAt as string}
              className="px-[6px] text-body-micro medium text-[var(--text-on-bg)]"
            />
          </td>
        </tr>

        {/* Collaborators */}
        <tr>
          <td className="w-[133px] py-3">
            <AssignItem
              icon={<Users className="!w-3.5 !h-3.5" />}
              text="Collaborators"
            />
          </td>
          <td className="py-3">
            <ProjectCollaboratorsSelect
              userId={userId}
              projectId={projectId}
              workspaceSlug={workspaceSlug}
              value={value.collaboratorIDs}
              onChange={(value: string) => onChange('collaboratorIDs', value)}
              readOnly={readOnly}
            />
          </td>
        </tr>

        {/* Workstream */}
        {/* <tr>
          <td className="py-3 align-top">
            <div className="flex items-center space-x-2 text-grey-300 py-1.5">
              <HashtagTrendingNumber size={16} color={(theme.textColor.grey as any)['300']} weight={2} />
              <span className="text-nowrap">Workstream</span>
            </div>
          </td>
          <td className="py-3">
            <ProjectTopicsSelect
              projectId={projectId}
              value={value.topicIDs}
              onChange={onChange('topicIDs')}
              readOnly={readOnly}
            />
          </td>
        </tr> */}

        {/* Goals */}
        {/* <tr>
          <td className="py-3 align-top">
            <AssignItem icon={<LightBulb />} text="Goals" />
          </td>
          <td className="py-3">
            <ProjectGoalsSelect
              workspaceSlug={workspaceSlug}
              projectId={projectId}
              value={value.goalIDs}
              onChange={(value) => onChange('goalIDs', value)}
              readOnly={readOnly}
            />
          </td>
        </tr> */}

        {/* Status */}
        <tr>
          <td className="py-3">
            <AssignItem
              icon={<Status className="!w-3.5 !h-3.5" />}
              text="Status"
            />
          </td>
          <td className="py-3">
            <ProjectStatusSelect
              value={value.status}
              onChange={(value) => onChange('status', value)}
              readOnly={readOnly}
            />
          </td>
        </tr>

        {/* Access */}
        <tr>
          <td className="py-3">
            <AssignItem
              icon={<Lock className="!w-3.5 !h-3.5" />}
              text="Access"
            />
          </td>
          <td className="py-3">
            <ProjectPublicAccessSelect
              projectId={projectId}
              checked={value.isPublic}
              onChange={(value) => onChange('isPublic', value)}
              readOnly={readOnly}
            />
          </td>
        </tr>

        {/* Resources */}
        <tr className="">
          <td className="py-3 flex">
            <AssignItem
              icon={<Files className="!w-3.5 !h-3.5" />}
              text="Resources"
            />
          </td>
          <td className="py-3">
            <ProjectLinksSelect
              projectId={projectId}
              value={value.links}
              onChange={(value) => onChange('links', value)}
              readOnly={readOnly}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default ProjectMetaDetails;
