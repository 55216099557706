import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import ToastMessage from 'src/components/ToastMessage';

import TextareaAutosize from 'react-textarea-autosize';
import { useCreateOneTaskComment } from 'src/api/comment/task-comment.service';
import { ArrowUp } from 'src/assets/icons';
import Avatar from 'src/components/ui/Avatar/Avatar';
import IconButton from 'src/components/ui/Button/IconButton';
import { IComment } from 'src/types/entities';
import TaskCommentItem from '../comments/tasks/TaskCommentItem';
import TaskComments from '../comments/tasks/TaskComments';

interface ITaskCommentSectionProps {
  myData: any;
  comments: IComment[];
}

const TaskCommentSection = (props: ITaskCommentSectionProps) => {
  const { myData, comments } = props;
  const [searchParams] = useSearchParams();
  const [comment, setComment] = useState('');

  const taskId = searchParams.get('taskId');

  const [createOneTaskComment, { loading: isCreatingComment }] =
    useCreateOneTaskComment();

  const handleCreateComment = () => {
    if (comment.length) {
      createOneTaskComment({
        variables: {
          data: {
            comment,
            task: {
              connect: { id: taskId },
            },
          },
        },
        onCompleted: () => {
          setComment('');
        },
        onError: (error) => {
          toast((t) => (
            <ToastMessage id={t.id} title={`Error ${error.message}`} />
          ));
        },
      });
    }
  };

  const handleInputKeyDown = async (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (!comment.length || isCreatingComment) return;
      handleCreateComment();
    }
  };

  return (
    <Fragment>
      <TaskComments
        comments={comments}
        renderComment={(comment: IComment) => (
          <TaskCommentItem comment={comment} />
        )}
      />

      <div className="w-full absolute z-5 pt-4 pb-8 px-5 bottom-0 left-0 border-t border-[var(--border-divider)]">
        <div className="w-full absolute h-[70px] top-[-40px] left-0 scrollBg_bottom" />
        <div className="flex items-end relative w-full">
          <div className="absolute left-2 pb-1.5">
            <Avatar
              name={myData?.detail.fullName}
              src={myData?.detail.avatar?.url}
              alt={`${myData?.detail.fullName} pfp`}
              size={24}
            />
          </div>
          <TextareaAutosize
            name="form-field-name"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            onKeyDown={handleInputKeyDown}
            placeholder="What would you like to share?"
            className={`input min-h-11 resize-none bg-none placeholder:text-base placeholder:!font-medium !rounded-full w-full !bg-[var(--backgrounds-default)] !pl-11 !p-2 placeholder:text-[var(--text-disabled)] shadow-none focus:!rounded-lg`}
          />
          <div className="absolute right-2 pb-1.5">
            <IconButton
              type="primary"
              size="standard"
              rounded="full"
              icon={<ArrowUp />}
              onClick={handleCreateComment}
              loading={isCreatingComment}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TaskCommentSection;
