import { FormEvent, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import LogoPurple from 'src/components/logo/LogoPurple';
import ToastMessage from 'src/components/ToastMessage';
import Button from 'src/components/ui/Button/Button';
import Input from 'src/components/ui/inputs/Default';
import Validator from 'validatorjs';
import { useSendAuthToken } from '../../api/auth/auth.service';
import SocialAuthButtonGroup from '../../components/SocialAuthButtonGroup';

type FormData = {
  email: string;
};

export default function RegisterPage() {
  const navigate = useNavigate();
  const [sendAuthToken, { data, loading, error }] = useSendAuthToken();

  const [formData, setFormData] = useState<FormData>({
    email: '',
  });

  const [formErrors, setFormErrors] =
    useState<Validator.ValidationErrors | null>(null);

  async function handleContinue(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const validation = new Validator(formData, {
      email: 'required|email',
    });

    if (validation.passes()) {
      try {
        setFormErrors(null);

        await sendAuthToken({
          variables: { email: formData.email },
          onCompleted: (data) => {
            const encodedEmail = encodeURIComponent(formData.email).replace(
              /\+/g,
              '%2B'
            );
            navigate(`/auth/verify-otp?email=${encodedEmail}&ui=basic`);
          },
        });
      } catch (error) {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title={`An error occurred. Please try again later.`}
          />
        ));
      } finally {
      }
    } else {
      setFormErrors(validation.errors.all());
    }
  }

  return (
    <div className="w-full flex flex-col gap-6 items-center justify-center">
      <div className="flex items-center gap-1">
        <LogoPurple className="w-[18.655px] h-auto" />
        <h1 className="text-heading font-semibold text-2xl text-[var(--text-default)]">
          overlap
        </h1>
      </div>
      <div className="w-full flex flex-col px-4 py-8 items-center justify-center gap-6 rounded-2xl bg-[var(--backgrounds-on-canvas)] md:px-8">
        <form
          onSubmit={handleContinue}
          className="w-full flex flex-col gap-10 justify-center"
        >
          <div className="flex flex-col gap-2 items-center">
            <h1 className="text-center text-heading-3-small bold text-[var(--text-default)] md:text-heading-3">
              Create an account
            </h1>
            <p className="text-body-small hidden text-center medium text-[var(--text-neutral)] md:block">
              Help your team stay on the same page
            </p>
            <p className="text-body-small block text-center medium text-[var(--text-neutral)] md:hidden md:text-body-large">
              Give your team the gift of seamless communication
            </p>
          </div>

          <div className="flex flex-col gap-8">
            <Input
              autoFocus
              type="email"
              value={formData.email}
              feedback={formErrors ? formErrors.email : ''}
              feedbackType={
                formErrors && formErrors.email ? 'error' : 'success'
              }
              label="Email address"
              placeholder="Enter your email address"
              onChange={(e) => setFormData({ email: e.target.value })}
            />
            <Button
              type="submit"
              btnType="primary"
              size="large"
              value={'Continue'}
              loading={loading}
              disabled={loading}
              className="w-full"
            />
          </div>
        </form>
        <p className="text-body-small medium text-[var(--text-neutral)] text-center">
          or
        </p>
        <SocialAuthButtonGroup />
        <p className="text-body-micro regular text-center text-[var(--text-neutral)] max-w-[336px]">
          By creating an account, you agree to the{' '}
          <span className="medium">Terms of Service</span> and{' '}
          <span className="medium">Privacy Policy</span> of Overlap.
        </p>
      </div>
    </div>
  );
}
