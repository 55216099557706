import {
  IPost,
  IProjectGoal,
  IProjectLink,
  IProjectTopic,
  IUser,
  IWorkspace,
  ProjectStatus,
} from 'src/lib/types';

export interface IProject {
  Tasks: ITask[];
  archivedAt: Date;
  collaboratorIDs: string[];
  collaborators: IUser[];
  createdAt: Date;
  createdBy: IUser;
  createdById: String;
  deleteAt: Date;
  description: string;
  goalIDs: string[];
  goals: IProjectGoal[];
  id: string;
  isPublic: Boolean;
  links: IProjectLink[];
  name: string;
  owner: IUser;
  ownerId: string;
  posts: IPost[];
  status: ProjectStatus;
  topicIDs: string[];
  topics: IProjectTopic[];
  workspace: IWorkspace;
  workspaceId: string;
}

export enum TaskPriority {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
}
export type TTaskPriority = 'HIGH' | 'LOW' | 'MEDIUM';

export enum TaskStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}
export type TTaskStatus = 'IN_PROGRESS' | 'NOT_STARTED' | 'COMPLETED';

export interface ITask {
  archivedAt: string;
  assignee: IUser;
  assigneeId: string;
  attachments: string[];
  createdAt: string;
  createdBy: IUser;
  createdById: string;
  deleteAt: string;
  description: string;
  dueDate: string;
  id: string;
  priority: TTaskPriority;
  project: string;
  projectId: string;
  status: TTaskStatus;
  title: string;
  updatedAt: string;
  workStream: IProjectTopic[];
  workStreamIDs: string[];
  comments: IComment[];
}

export type ITaskInbox = {
  subject: string;
  taskAuthorFullName: string;
  taskTitle: string;
  taskDescription: string;
  taskCreatedAt: string;
  projectId?: string;
  projectName?: string;
  taskId?: string;
};

export interface IComment {
  id: string;
  author: IUser;
  comment: string;
  createdAt: string;
  responses: IComment[];
  post: IPost;
  task: ITask;
}
