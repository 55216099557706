import { AnimatePresence, motion } from 'framer-motion';
import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import TextareaAutosize from 'react-textarea-autosize';
import { useMe } from 'src/api/auth/auth.service';
import { useCreateOneTaskComment } from 'src/api/comment/task-comment.service';
import { ArrowUp } from 'src/assets/icons';
import ToastMessage from 'src/components/ToastMessage';
import Avatar from 'src/components/ui/Avatar/Avatar';
import Button from 'src/components/ui/Button/Button';
import IconButton from 'src/components/ui/Button/IconButton';
import HorizontalDivider from 'src/components/ui/HorizontalDivider';
import StatusTag from 'src/components/ui/tags/StatusTags';
import { getUniqueCommentAuthors } from 'src/lib/helpers';
import { IComment } from 'src/types/entities';
import ReplyCommentBody from './ReplyCommentBody';
import TaskCommentBody from './TaskCommentBody';

function TaskCommentItem({ comment }: { comment: IComment }) {
  const { data: myData } = useMe();
  const [createOneTaskComment, { loading }] = useCreateOneTaskComment();
  const commentAuthors = getUniqueCommentAuthors(comment.responses);

  const [state, setState] = useState({
    showReplyInput: false,
    response: '',
    showReplies: false,
    showReplyButton: false,
  });

  const handleReply = () => {
    if (state.response.length) {
      createOneTaskComment({
        variables: {
          data: {
            comment: state.response,
            parent: {
              connect: { id: comment.id },
            },
            task: {
              connect: { id: comment.task.id },
            },
          },
        },
        onCompleted: (data) => {
          setState({
            ...state,
            response: '',
            showReplyInput: false,
          });
        },
        onError: (error) => {
          toast((t) => (
            <ToastMessage
              id={t.id}
              title={'There was an error sending this comment.'}
            />
          ));
        },
      });
    }
  };

  const handleInputKeyDown = async (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (!state.response.length || loading) return;
      handleReply();
    }
  };

  return (
    <Fragment>
      <div
        className="duration-1000 flex flex-col gap-4"
        onMouseEnter={() => {
          if (state.showReplyInput) return;
          setState({ ...state, showReplyButton: true });
        }}
        onMouseLeave={() => {
          if (state.showReplyInput) return;
          setState({ ...state, showReplyButton: false });
        }}
      >
        <TaskCommentBody comment={comment} />

        <div className="flex items-center justify-start gap-1 relative group">
          {commentAuthors.length > 0 && (
            <div className="avatars-group flex items-center -space-x-1">
              {commentAuthors.slice(0, 3).map((user, key) => (
                <div key={key}>
                  <Avatar
                    name={user.detail.fullName}
                    size={16}
                    alt={user.detail.fullName}
                    src={user.detail.avatar?.url}
                    className="border border-[var(--backgrounds-on-canvas)]"
                  />
                </div>
              ))}
            </div>
          )}

          <button
            onClick={() => {
              if (!comment.responses.length) return;
              setState({ ...state, showReplies: !state.showReplies });
            }}
            className="w-[fit-content]"
          >
            <StatusTag
              color={'default'}
              size={'medium'}
              value={
                comment.responses.length > 0 ? comment.responses.length : ''
              }
              leftIcon={
                <svg
                  className=""
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M4 6.5L2 4.5M2 4.5L4 2.5M2 4.5H8.5C9.32845 4.5 10 5.17155 10 6V9.5"
                    stroke="#8D8D8D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className=" !fill-none"
                  />
                </svg>
              }
            />
          </button>

          <motion.div
            layout
            className={'w-[fit-content]'}
            initial={{ opacity: 0 }}
            animate={{
              opacity: state.showReplyButton ? 1 : 0,
            }}
          >
            <Button
              value={state.showReplyInput ? 'Cancel' : 'Reply'}
              size={'small'}
              btnType={'ghost'}
              onClick={() =>
                setState({ ...state, showReplyInput: !state.showReplyInput })
              }
              className={'!h-5'}
            />
          </motion.div>
        </div>

        <AnimatePresence>
          {state.showReplies && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
            >
              {comment.responses.length > 0 && (
                <div className="comments p-4 md:p-6 flex flex-col gap-3 rounded-lg bg-[var(--backgrounds-default)]">
                  {comment.responses.map((response, index) => (
                    <div key={response.id}>
                      <ReplyCommentBody comment={response} />
                      {index !== comment.responses.length - 1 && (
                        <div className="pt-3">
                          <HorizontalDivider />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <AnimatePresence>
        {state.showReplyInput && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            className="flex items-center w-full relative"
          >
            <div className="absolute left-0 inset-y-0 px-2 top-2.5 grid place-content-start">
              <Avatar
                name={myData?.me.detail.fullName}
                src={myData?.me.detail.avatar?.url}
                alt={`${myData?.me.detail.fullName} pfp`}
                size={24}
              />
            </div>
            <TextareaAutosize
              autoFocus
              value={state.response}
              onChange={(e) => setState({ ...state, response: e.target.value })}
              onKeyDown={handleInputKeyDown}
              disabled={loading}
              placeholder="Add a reply"
              className={`textarea-autosize-reply input resize-none max-h-[182px] bg-none placeholder:text-base placeholder:!font-medium w-full !bg-[var(--backgrounds-default)] !pl-11 !pr-11 !p-2 placeholder:text-[var(--text-disabled)] shadow-none`}
            />
            <div className="absolute right-2 inset-y-0 bottom-1.5 grid place-content-end">
              <IconButton
                onClick={handleReply}
                type="primary"
                size="standard"
                rounded="full"
                disabled={loading}
                loading={loading}
                icon={<ArrowUp />}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Fragment>
  );
}

export default TaskCommentItem;
