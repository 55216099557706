import { useMutation } from '@apollo/client';
import {
  CREATE_ONE_POST_COMMENT,
  DELETE_ONE_POST_COMMENT,
  UPDATE_ONE_POST_COMMENT,
} from 'src/lib/mutations';
import { POSTS } from 'src/lib/queries';
import { useApolloOptions } from '../api';

export function useCreateOnePostComment() {
  const options = useApolloOptions();

  return useMutation(CREATE_ONE_POST_COMMENT, {
    ...options,
    refetchQueries: [POSTS],
  });
}

export function useUpdateOnePostComment() {
  const options = useApolloOptions();

  return useMutation(UPDATE_ONE_POST_COMMENT, {
    ...options,
    refetchQueries: [POSTS],
  });
}

export function useDeleteOnePostComment() {
  const options = useApolloOptions();

  return useMutation(DELETE_ONE_POST_COMMENT, {
    ...options,
    refetchQueries: [POSTS],
  });
}
