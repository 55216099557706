import {
  forwardRef,
  ReactElement,
  ReactNode,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Check, ChevronDown, Plus } from 'src/assets/icons';
import { cn } from 'src/lib/utils';
import Button from '../ui/Button/Button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectViewport,
} from '../ui/Select';
import StatusTag from '../ui/tags/StatusTags';

export type ISelectValue = string | string[];

export interface CustomSelectRefs {
  triggerRef: React.RefObject<HTMLButtonElement>;
  contentRef: React.RefObject<HTMLDivElement>;
}
export interface ISelectItem {
  icon?: ReactNode;
  label: string;
  value: string;
  others?: any;
}

export interface SelectProps {
  value?: ISelectValue;
  options?: ISelectItem[];
  triggerIcon?: ReactNode;
  onOpenChange?: (isOpen: boolean) => void;
  onChange?: (value: ISelectValue) => void;
  onValueChange?: (value: string) => void;
  renderOption?: (option: ISelectItem) => ReactNode;
  renderValue?: (value: ISelectValue) => ReactNode;
  customValueItem?: (option: ISelectItem) => ReactNode;
  customOptionItem?: (option: ISelectItem) => ReactNode;
  children?: ReactNode;
  placeholder?: string | ReactNode;
  renderSearch?: boolean;
  searchPlaceholder?: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  alignment?: 'center' | 'start' | 'end';
  multiple?: boolean;
  contentClassName?: string;
  triggerClassName?: string;
  triggerButtonSize?: 'small' | 'medium' | 'large';
  addOptionProps?: {
    onAddOption: (newOption: string) => void;
    buttonText?: string;
    buttonLoading?: boolean;
  };
}

const CustomSelect = forwardRef<CustomSelectRefs, SelectProps>(
  (
    {
      options = [],
      value,
      triggerIcon,
      onChange,
      renderOption,
      placeholder,
      renderSearch = true,
      placement = 'bottom',
      alignment = 'end',
      customOptionItem,
      customValueItem,
      multiple = false,
      contentClassName,
      triggerClassName,
      triggerButtonSize = 'medium',
      addOptionProps,
      ...props
    }: SelectProps,
    ref
  ): ReactElement => {
    const inputEl = useRef<HTMLInputElement>(null);
    const internalTriggerRef = useRef<HTMLButtonElement>(null);
    const internalContentRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      triggerRef: internalTriggerRef,
      contentRef: internalContentRef,
    }));

    const [searchValue, setSearchValue] = useState('');

    const focusOnInput = () => {
      setTimeout(() => inputEl.current?.focus(), 0);
    };

    const renderSelectedValue = useMemo(() => {
      if (!value || (Array.isArray(value) && value.length === 0)) {
        return (
          <StatusTag
            leftIcon={triggerIcon}
            rightIcon={<ChevronDown className="w-3 h-3" />}
            color="default"
            size={triggerButtonSize}
            value={placeholder as string}
          />
        );
      }

      if (props.renderValue) {
        return props.renderValue(value);
      }

      if (multiple && Array.isArray(value)) {
        return (
          <StatusTag
            leftIcon={triggerIcon}
            rightIcon={<ChevronDown className="w-3 h-3" />}
            color="default"
            size={triggerButtonSize}
            value={`${value.length} selected`}
          />
        );
      }

      const selectedOption = options.find((option) => option.value === value);

      if (customValueItem && selectedOption) {
        return customValueItem(selectedOption);
      }

      return (
        <StatusTag
          leftIcon={triggerIcon}
          rightIcon={<ChevronDown className="w-3 h-3" />}
          color="default"
          size={triggerButtonSize}
          value={selectedOption?.label || (placeholder as string)}
        />
      );
    }, [
      value,
      props,
      multiple,
      options,
      customValueItem,
      triggerIcon,
      triggerButtonSize,
      placeholder,
    ]);

    const selectOptions = useMemo(() => {
      if (searchValue.trim() === '') return options;
      return options.filter((option) =>
        new RegExp(searchValue, 'i').test(option.label)
      );
    }, [searchValue, options]);

    const handleChange = (newValue: string) => {
      if (!newValue || newValue.trim() === '') return;
      if (multiple) {
        const currentValue = (value as string[]) || [];
        const updatedValue = currentValue.includes(newValue)
          ? currentValue.filter((v) => v !== newValue)
          : [...currentValue, newValue];
        onChange?.(updatedValue);
      } else {
        onChange?.(newValue);
      }
    };

    const renderSelectOptions = () => {
      return selectOptions.map((option) => {
        const isSelected = multiple
          ? (value as string[])?.includes(option.value)
          : option.value === value;

        if (renderOption) {
          return renderOption(option);
        }

        return (
          <SelectItem
            key={option.value}
            value={option.value}
            className={cn(
              'px-2 min-h-[32px] hover:bg-[var(--backgrounds-hover-clicked)] cursor-pointer rounded',
              isSelected && 'bg-[var(--backgrounds-hover-clicked)]'
            )}
          >
            {!customOptionItem ? (
              <span className="text-body-small medium text-[var(--text-neutral)]">
                {option.label}
              </span>
            ) : (
              customOptionItem(option)
            )}

            {isSelected && (
              <span className="z-1 absolute right-2 flex items-center justify-center">
                <Check className="h-4 w-4" />
              </span>
            )}
          </SelectItem>
        );
      });
    };

    const renderFallback = () => {
      if (selectOptions.length === 0) {
        if (addOptionProps) {
          return (
            <div className="flex flex-col items-center">
              <p className="text-body-micro medium text-[var(--text-disabled)] text-center p-4">
                No search found for "
                <span className="font-semibold">{searchValue}</span>" but you
                can create an option below
              </p>
              <Button
                type="button"
                size="small"
                loading={addOptionProps.buttonLoading}
                disabled={addOptionProps.buttonLoading}
                onClick={() => addOptionProps.onAddOption(searchValue)}
                className="mb-2 w-auto"
                icon="left"
                value={addOptionProps.buttonText || 'Add option'}
                btnType={'neutral'}
              >
                <Plus />
              </Button>
            </div>
          );
        } else {
          return (
            <p className="text-body-micro medium text-[var(--text-disabled)] text-center p-4">
              No search found for "
              <span className="font-semibold">{searchValue}</span>" in your
              workspace.
            </p>
          );
        }
      }
      return null;
    };

    return (
      <Select
        value={Array.isArray(value) ? value.join(', ') : value}
        onOpenChange={(props.onOpenChange, focusOnInput)}
        onValueChange={handleChange}
        {...props}
      >
        <SelectTrigger ref={internalTriggerRef} className={triggerClassName}>
          {renderSelectedValue}
        </SelectTrigger>
        <SelectContent ref={internalContentRef} className={contentClassName}>
          {renderSearch && (
            <div className="w-full border-b border-[var(--border-divider)] mb-1">
              <input
                autoFocus
                ref={inputEl}
                placeholder={props.searchPlaceholder}
                className="input-clear !h-[34px] py-3 !pl-2 !pr-2 w-full text-[var(--text-default)] text-body-small regular placeholder:text-[var(--text-disabled)]"
                value={searchValue}
                onChange={(e) => {
                  focusOnInput();
                  setSearchValue(e.target.value);
                }}
              />
            </div>
          )}
          <SelectViewport className="max-h-[220px]">
            {renderSelectOptions()}
            {renderFallback()}
          </SelectViewport>
        </SelectContent>
      </Select>
    );
  }
);

export default CustomSelect;
