import { useCallback, useEffect, useMemo, useState } from 'react';
import { IPost } from 'src/lib/types';
import { ITask } from 'src/types/entities';

interface FilterCriteria {
  filteredWorkstreams: string[];
  filteredMembers: string[];
  filteredTypes?: string[];
}

type FilterOptions = {
  itemType: 'post' | 'task';
};

function useFilteredItems<T extends IPost | ITask>(
  items: T[],
  options: FilterOptions
) {
  const [filterCriteria, setFilterCriteria] = useState<FilterCriteria>({
    filteredWorkstreams: [],
    filteredMembers: [],
    ...(options.itemType === 'post' && { filteredTypes: [] }),
  });

  const [filteredItems, setFilteredItems] = useState<T[]>([]);

  const filterItems = useCallback(
    (items: T[], criteria: FilterCriteria) => {
      return items.filter((item) => {
        if (options.itemType === 'post') {
          const post = item as IPost;

          if (criteria.filteredWorkstreams.length > 0) {
            if (!criteria.filteredWorkstreams.includes(post?.topic?.id)) {
              // console.log('filteredWorkstreams', post?.topic?.id);
              return false;
            }
          }

          if (criteria.filteredTypes && criteria.filteredTypes.length > 0) {
            if (!criteria.filteredTypes.includes(post?.type)) {
              return false;
            }
          }

          if (criteria.filteredMembers.length > 0) {
            const postMemberIds = [post?.author?.id, ...post.mentionedUserIDs];
            if (
              !criteria.filteredMembers.some((memberId) =>
                postMemberIds.includes(memberId)
              )
            ) {
              return false;
            }
          }
        } else {
          const task = item as ITask;

          if (criteria.filteredWorkstreams.length > 0) {
            if (
              !task.workStreamIDs.some((id) =>
                criteria.filteredWorkstreams.includes(id)
              )
            ) {
              return false;
            }
          }

          if (criteria.filteredMembers.length > 0) {
            if (!criteria.filteredMembers.includes(task?.assigneeId)) {
              return false;
            }
          }
        }

        return true;
      });
    },
    [options.itemType]
  );

  const memoizedItems = useMemo(() => items, [items]);

  useEffect(() => {
    setFilteredItems(filterItems(memoizedItems, filterCriteria));
  }, [memoizedItems, filterCriteria, filterItems]);

  return { filteredItems, filterCriteria, setFilterCriteria };
}

export default useFilteredItems;
